import React, { Component, PureComponent, useState } from 'react';
import s from './AllFleet.scss';
import m from './../../Monitor/Monitor.scss';
import t from './../../Monitor/TableNew.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Search from '../../Common/Search';
import { connect } from 'react-redux';
import BlurIfNoSubscription from '../../Signup/BlurIfNoSubscription';
import ShowHideEye from '../../Common/ShowHideEye';
import RaisedButton from '../../Common/RaisedButton';
import Link from '../../Link/Link';
import AddPosition from '../AddPosition';
import ThemeToggle from '../../Common/ThemeToggle';
import { findLink, fleetLinks, renderFleetMenu } from './../shared';
import { isUserSubscribedToMarketplace, selector, throttle, postRegularForm } from '../../../core/utils';
import Loader from '../../Common/Loader';
import memoizeOne from 'memoize-one';
import Row from './Row';
import TableHead from './TableHead';
import { formErrorMessage, loadMoreRowsIfNoScroll, transformSelected } from '../../Monitor/shared';
import { hideUnhideVessels } from './../../../actions/monitor';
import { sendOpenPosition } from '../../../actions/vessel';
import { getAccountFilters } from '../../../actions/login';
import { updateVesselInFleet, updateAllVesselsInFleet, setSaveRowsAndScroll, updateVesselsInFleet } from './../../../actions/allFleet';
import AllFleetFilterNew from '../../Monitor/Filter/AllFleetFilterNew';
import SaveFilterWrapper from '../../Monitor/Filter/SaveFilterWrapper';
import SortVessel from '../../Common/SortVessel';
import ChipsAutocomplete from '../../Common/Tags/ChipsAutocomplete';
import { areFiltersApplied } from '../../helpers';
import EmptyListResult from '../../Common/EmptyListResults';
import EmptyList from '../../Common/EmptyList';
import Ship from '../../Icons/Ship';
import TableActionsHead from '../../Common/TableActionsHead';
import PrintPopover from '../../Common/PrintPopover';
import ExcelIcon from '../../Icons/ExcelIcon';
import { markVesselAsSelected } from '../../Monitor/shared';
import CustomTooltip from '../../Common/CustomTooltip';
import Share from '../../Common/Share';
import AddToSelected from '../../Monitor/AddToSelected';
import ExportButtonWithTooltip from "../../Monitor/Export/ExportButtonWithTooltip";
import AddPopover from '../../Common/AddPopover';
import { MenuItem } from 'material-ui/Menu';
import AddPositionDialog from '../../Vessel/Positions/PositionDialog';
import history from '../../../core/history';

// used for virtual list calculations
const ROW_THRESHOLD = 5;
const ROW_HEIGHT = 49;

const ALL_FLEET_LINK = findLink("all");

const headerStyle = { fontWeight: 700, fontSize: '12px', marginTop: "0px", marginBottom: "0px", whiteSpace: "nowrap", color: '#333333' };

export class Table extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      topMock: 0,
      bottomMock: 0,
      isSubscribed: isUserSubscribedToMarketplace(this.props.user),
      refList: [
        {
          id: 1,
          name: selector.bind(this, (state, props) => props.isHidden ? "Show vessels" : "Hide vessels"),
          disabled: selector.bind(this, this.disableNoSubscription((state, props) => props.rows?.find?.(row => !!row.selected))),
          handler: this.toggleShowHideVessels,
        },
      ],
    };

    this._wrapper = React.createRef();
  }

  async componentDidMount() {
    if (!this._wrapper.current) return;

    if (this.props.saveRowsAndScroll && this._wrapper.current) {
      this._wrapper.current.scroll(0, this.props.saveRowsAndScroll);
    }

    const { scrollTop, scrollHeight, clientHeight } = this._wrapper.current;

    const { topMock, bottomMock } = this.calculateTopBottomMockOffset({
      scrollTop,
      scrollHeight,
      clientHeight,
    });
    this.setState({
      topMock,
      bottomMock,
    });
  }

  disableNoSubscription = fn => (state, props) => state.isSubscribed ? fn(state, props) : false

  handleScroll = ev => {
    if (this.props.loading) return;
    ev.persist();
    this.handleVirtualListAndPagination(ev);
  }

  calculateTopBottomMockOffset = ({ scrollTop, scrollHeight, clientHeight }) => {
    const topMock = Math.floor(scrollTop / ROW_HEIGHT) - ROW_THRESHOLD;

    const remainingScrollHeight = scrollHeight - (scrollTop + clientHeight);
    const bottomMock = Math.floor(remainingScrollHeight / ROW_HEIGHT) - ROW_THRESHOLD;

    return {
      topMock,
      bottomMock,
    };
  }

  _prevScroll = 0;

  handleVirtualListAndPagination = (ev) => {
    const { next, page, isMoreLoading } = this.props;

    const wrapper = this._wrapper.current;
    if (!wrapper) return;

    const { scrollTop, scrollHeight, clientHeight } = wrapper;

    if (Math.abs(scrollTop - this._prevScroll) < ROW_HEIGHT * (ROW_THRESHOLD)) {
      return;
    }

    this._prevScroll = scrollTop;

    const { topMock, bottomMock } = this.calculateTopBottomMockOffset({
      scrollTop,
      scrollHeight,
      clientHeight,
    });

    this.setState({
      topMock,
      bottomMock,
    });

    // start fetch new page when there are only 5 rows remaining for scroll
    if (!isMoreLoading && (scrollTop + clientHeight) >= (scrollHeight - (ROW_HEIGHT * ROW_THRESHOLD))) {
      if (next === page || !next) {
        return;
      }
      this.props.handleLoadMore(this.props.page + 1);
    }
  }

  calculateRows = rows => {
    const { topMock, bottomMock } = this.state;

    const virtualRows = rows.slice(Math.max(0, topMock), rows.length - Math.max(0, bottomMock));
    return virtualRows;
  }

  renderRows = (rows=[]) =>{
    const {filters, isDark, selectedVessel,create, lastFilters} = this.props;
    const { handleRowSelected, selectVessel } = this;
    const isRowSelected = vessel => !create && (selectedVessel?._id || selectedVessel?.vessel?._id) === vessel._id;
    if(this.props.rows.length === 0) {
      if(areFiltersApplied({...filters,...lastFilters?.allFleetFilter})) return <EmptyListResult isBgDark={isDark} onClear={this.handleClearFilters}/>
      return <EmptyList isBgDark={isDark} title={'No fleet yet'} Icon={Ship}/>
    }
    return rows.map((vessel, i) => <Row search={filters.search} user={this.props.user} handleRowSelected={handleRowSelected} isDark={isDark} key={vessel._id} handleVesselSelected={selectVessel.bind(this, vessel, true)} selected={isRowSelected(vessel)} vessel={vessel} detailsBaseUrl={this.props.detailsBaseUrl} />)
  }

  renderVirtualList = () => {
    const { topMock, bottomMock } = this.state;
    const { fetchFailed, loading, rows, filters, isDark, create, selectedVessel } = this.props;
    const { handleRowSelected, selectVessel } = this;

    if (fetchFailed) {
      return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <h2>Something went wrong.</h2>
          <a onClick={() => this.props.handleLoadRows(1)}>Try again</a>
        </div>
      );
    }

    const loader = <Loader isDark={isDark} />;
    const topMockHeight = topMock > 0 ? topMock * ROW_HEIGHT : 0;
    const bottomMockHeight = bottomMock > 0 ? bottomMock * ROW_HEIGHT : 0;

    const isRowSelected = vessel => !create && (selectedVessel?._id || selectedVessel?.vessel?._id) === vessel._id;
    return (
      <div className={t.body} ref={this._wrapper} onScroll={this.handleScroll}>
        <div style={{ height: topMockHeight }} />
          {(loading || this.state.clearFiltersLoading) ? loader : this.renderRows(this.calculateRows(rows))}
        <div style={{ height: bottomMockHeight }} />
      </div>
    );
  }

  selectVessel = vessel => {
    const { _wrapper } = this;

    this.props.setSaveRowsAndScroll(_wrapper.current.scrollTop);

    this.props.selectVessel(vessel);
  }

  toggleShowHideVessels = async (ev, shouldHideUnhide) => {
    try {
      const { isHidden } = this.props;
      const vessels = transformSelected(this.props.rows);
      const originalRows = [...this.props.rows];
      const originalScrollTop = this._wrapper.current.scrollTop;
      if (shouldHideUnhide) {
        const res = await this.props.hideUnhideVessels(vessels, !isHidden);
        if (!res.errors?.length) {
          const { _wrapper, _header } = this;
          loadMoreRowsIfNoScroll({
            wrapper: _wrapper.current,
            header: _header,
          }, this.props.rows, () => this.props.handleLoadRows());
         if (!isHidden) {
           this.adjustTopScrollAfterHide(this.state.topMock, vessels, originalRows, _wrapper.current, _header,originalScrollTop);
         }
        }
      } else {
        await this.props.handleLoadRows({
          isHidden: !isHidden,
        });
      }
    } catch (error) {
      console.error(error);
      this.context.showMessage(formErrorMessage(error, shouldHideUnhide));
    }
  }

  adjustTopScrollAfterHide = (topMock, idList, rows, wrapper, header, wrapperScrollTop) => {
    let topVesselsCount = rows.slice(0, topMock).filter(v => idList.indexOf(v._id) !== -1).length;
    let visibleRows = this.calculateRows(rows);
    visibleRows = visibleRows.slice(0, parseInt(visibleRows.length / 2, 10));
    const visibleVesselsCount = visibleRows.filter(v => idList.indexOf(v._id) !== -1).length;
    topVesselsCount += visibleVesselsCount;
    if (!topVesselsCount) {
      return;
    }
    let newTopMock = topMock - topVesselsCount;

    let newScroll = wrapperScrollTop - (topVesselsCount * ROW_HEIGHT) - header.clientHeight;
    if (newTopMock <= 0) {
      newScroll = 0;
      newTopMock = 0;
    }
    if (newTopMock === topMock) {
      return;
    }
    wrapper.scrollTop = newScroll;
    this.setState({ topMock: newTopMock });
  }

  componentDidUpdate() {
    if (this.props.loading && this._wrapper.current) {
      this._wrapper.current.scroll?.(0, 0);
      this.setState({
        topMock: 0,
        bottomMock: 0,
      });
    }
  }

  handleSearch = query => {
    if (query?.length >= 2 || !query?.length) {
      let newFilters = {
        ...this.props.filters,
        search: query,
      };

      if (!newFilters.search?.length) {
        Reflect.deleteProperty(newFilters, "search");
      }
      this.props.handleLoadRows({
        filters: newFilters,
      });
    }
  }

  // Printing PDF is disabled until we decide what exactly and how should we export to PDF
  // handlePrint = async (showTCRate = false, format = 'pdf') => {
  //   const selectedVessels = this.getSelectedVessels(this.props.rows).map(row => row._id);
  //   const filters = {vesselIds:selectedVessels}
  //   const sort = {...this.props.sort,isLinerTraderFirst:true};
  //   postRegularForm('/api/v1/monitor/all-fleet/export?format=' + format, {filters,showTCRate,sort});
  // };

  markAsSelected = (vessels, isDelete) => {
    const vesselsToUpdate = vessels.map(vessel => ({...vessel,inSelectedVessels: true}));
    this.props.updateVesselsInFleet(vesselsToUpdate);
  }

  handleFilter = filters => {
    if (this.props.saveRowsAndScroll) {
      this.props.setSaveRowsAndScroll(null);
      return;
    }
    if (this.props.lastFilters?.allFleetFilter) {
      filters = { ...filters, ...this.props.lastFilters.allFleetFilter };
    }
    this.props.handleLoadRows({
      filters,
    });
  }

  handleSort = sort => {
    this.props.handleLoadRows({
      sort,
    });
  }

  handleRowSelected = (vessel) => {
    this.props.updateVesselInFleet(vessel);
  }

  handleAllSelected = (isSelected) => {
    this.props.updateAllVesselsInFleet({
      field: "selected",
      val: isSelected,
    });
  }

  setClearAllFilters=(clearFltersMethod)=>{
    this.clearAllFilters = clearFltersMethod;
  }

  handleClearFilters = async () =>{
    this.setState({clearFiltersLoading:true})
    if(this.clearAllFilters) await this.clearAllFilters()
    this.setState({clearFiltersLoading:false})
    this.handleFilter({})
  }

  countSelectedVessels = memoizeOne(vessels => vessels.filter(vessel => !!vessel?.selected).length || 0);

  getSelectedVessels = memoizeOne(vessels => vessels.filter(vessel => !!vessel?.selected));

  handleAddPosition = position => {
    return this.props.sendOpenPosition(position, this.props.user);
  }

  render() {
    const { handleLoadRows, sort, sortFields, changeTheme, baseUrl = "", hiddenVesselsCount: hiddenCount, filters, isDark, loading = false, isHidden = false, rows = [], user , navLinks} = this.props;

    const { handleFilter, handleSort, handleSearch, toggleShowHideVessels, handleAddPosition, handleAllSelected, handlePrint, markAsSelected, setClearAllFilters } = this;
    const query = filters?.query || "";
    const { refList } = this.state;

    const selectedVesselsCount = this.countSelectedVessels(rows);
    const selectedVessels = this.getSelectedVessels(rows)
    const isUserAnonymous = !this.props.user;

    const loggedInUserHeadProps = {
      query,
      handleSearch,
      isDark,
      handleFilter,
      filters,
      user,
      sort,
      handleSort,
      sortFields,
      loading,
      toggleShowHideVessels,
      isHidden,
      selectedVesselsCount,
      hiddenCount,
      baseUrl,
      handleAddPosition,
      changeTheme,
      navLinks,
      setClearAllFilters,
      selectedVessels,
      handleAllSelected,
      markAsSelected,
      renderTabs: this.props.renderTabs
    };

    return (
      <div
        className={cx(
          t.table,
          isDark ? t.dark : '',
          t.my_fleet_table
        )}
      >
        <div className={cx(t.wrapper_header_body, t.wrapper_header_body_vessel_table, t.all_fleet)}>
          <div className={cx(t.wrapper_header, t.light)} ref={el => this._header = el} style={{ zIndex: 3 }}>
            {
              isUserAnonymous
                ? (
                  <AnonymousUserHead changeTheme={changeTheme} isDark={isDark} query={query} handleSearch={this.handleSearch} />
                )
                : (
                  <LoggedInUserHead
                      {
                        ...loggedInUserHeadProps
                      }
                  />
                )
            }
          </div>
          <TableHead isUserAnonymous={isUserAnonymous} refList={refList} rows={rows} selectAll={handleAllSelected} isDark={isDark} />
          {
            this.renderVirtualList()
          }

        </div>
      </div>
    );
  }
}

function AnonymousUserHead(props) {
  const {
    query,
    handleSearch,
    isDark,
    changeTheme,
  } = props;
  return (
    <div className={cx(t.table_row, t.header, t.static_color)}>
      <div style={{ display: 'flex', alignItems: 'center', width: "100%" }}>
        <h5 style={headerStyle}>
          ALL FLEET
        </h5>

        <Search
          style={{ width: '226px', marginRight: '8px', height: "24px" }}
          query={query}
          handleSearch={handleSearch}
          placeholder={"Enter Ship name or IMO"}
        />

        <div style={{marginLeft: "auto"}}>
          <ThemeToggle dark={isDark} changeTheme={changeTheme} />
        </div>
      </div>
    </div>
  );
}

const LoggedInUserHead = ({
  handleSearch,
  isDark,
  handleFilter,
  filters,
  user,
  sort,
  handleSort,
  sortFields,
  loading,
  toggleShowHideVessels,
  isHidden,
  selectedVesselsCount,
  hiddenCount,
  baseUrl,
  handleAddPosition,
  changeTheme,
  navLinks,
  setClearAllFilters,
  selectedVessels,
  handleAllSelected,
  markAsSelected,
  renderTabs
}) => {
  const [addPositionDialogOpened, setPositionDialogOpen] = useState(false);
  const handleToggleAddPositionDialog = () => setPositionDialogOpen(!addPositionDialogOpened);

  const addPosition = async (position) => {
    try {
      const res = await Promise.resolve(handleAddPosition(position));
      if (res?.status === 200) {
        handleToggleAddPositionDialog();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddVessel = () => {
    history.push(`${baseUrl}create`);
  }
  
  return (
    <div className={cx(t.table_row, t.header, t.static_color)}>

      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {renderTabs && renderTabs()}
        {selectedVessels.length > 0 && (
          <TableActionsHead handleClose={() => handleAllSelected(false)} selectedCount={selectedVessels.length}>
            {/* Printing PDF is disabled until we decide what exactly and how should we export to PDF */}
            {/*<PrintPopover*/}
            {/*  style={{ width: "20px" }}*/}
            {/*  iconStyle={{*/}
            {/*    fill: `var(--filter-sort-clr)`,*/}
            {/*    width: 20,*/}
            {/*    marginRight: "0px",*/}
            {/*    marginLeft: "0px",*/}
            {/*    height: 20*/}
            {/*  }}*/}
            {/*  canPrint*/}
            {/*  handlePrint={handlePrint}*/}
            {/*/>*/}
            <ExportButtonWithTooltip
              selectedVessels={selectedVessels}
              storageKey='fleetExportFields'
              hideVesselOpenPositions
              isDark={isDark}
            />
            <CustomTooltip style={{ height: "auto" }} tooltip="Share" dark={isDark}>
              <Share key="4" selectedVessels={selectedVessels}/>
            </CustomTooltip>
            <AddToSelected dark={isDark} handleSuccess={markAsSelected}
                          selectedVessels={selectedVessels}/>
          </TableActionsHead>
        )}

        {/*
          SaveFilterWrapper is toggled with css and not by conditional rendering because it re-loads the whole vessel list from API on remount.
          Conditional rendering is causing remount
          */}
        <SaveFilterWrapper
          setClearAllFilters={setClearAllFilters}
          style={{ display: selectedVessels.length ? "none" : "flex", width: "100%" }}
          lastFilterFieldName="allFleetFilter"
        >
          <ChipsAutocomplete
            filters={filters}
            handleSearch={handleFilter}
            entity="cargoRequest"
            name="tags"
            className={s.all_fleet_search}
          />

          <AllFleetFilterNew
            isDark={isDark}
            handleFilter={handleFilter}
            defaultValues={filters}
            user={user}
            name="filter"
          />

          <SortVessel
            isDark={isDark}
            sort={sort}
            handleSort={handleSort}
            values={sortFields}
            name="sort"
          />
        </SaveFilterWrapper>
      </div>
      <div className={t.main_buttons} style={{ alignItems: 'center', display: 'flex' }}>
        <div style={{ margin: "0px 2px" }}>
          <BlurIfNoSubscription dontBlur>
            <ShowHideEye
              disabled={loading}
              toggleShowHideOrders={toggleShowHideVessels}
              showHiddenOrders={isHidden}
              isDark={isDark}
              selectedOrdersCount={selectedVesselsCount}
              hiddenCount={hiddenCount}
              tooltipProps={{
                horizontalAlign: "right",
              }}
            />
          </BlurIfNoSubscription>
        </div>
        <div className={t.add_buttons}>
          <Link to={`${baseUrl}create`}>
            <RaisedButton
              label="add vessel"
              primary
              styles="xs"
            />
          </Link>
          <AddPosition handleAddPosition={handleAddPosition} baseUrl={baseUrl} />
        </div>
        <div className={t.add_buttons_min}>
          <AddPopover>
              <MenuItem onClick={handleAddVessel} style={{ fontSize: "13px", lineHeight: "28px", minHeight: "28px", minWidth: "158px" }}>
                Add vessel
              </MenuItem>
            <MenuItem
              onClick={handleToggleAddPositionDialog}
              style={{ fontSize: "13px", lineHeight: "28px", minHeight: "28px", minWidth: "158px" }}
            >
              Add position
            </MenuItem>
          </AddPopover>
        </div>
        <ThemeToggle dark={isDark} changeTheme={changeTheme} />
      </div>
      { addPositionDialogOpened && 
        <AddPositionDialog
          createLink={`${baseUrl}create`}
          handleClose={handleToggleAddPositionDialog}
          handleSendNewPosition={addPosition}
        />
      }
    </div>
  )};

export default connect(state => ({ ...({ ...state.allFleet, rows: state.allFleet.data }), user: state.login.user, lastFilters: state.login.lastFilters }), { hideUnhideVessels, sendOpenPosition, updateVesselInFleet, updateAllVesselsInFleet, setSaveRowsAndScroll, getAccountFilters, updateVesselsInFleet })(withStyles(t, m, s)(Table));
