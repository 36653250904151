import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import s from './Emails.scss';
import { getEmailDetails, markAsRead, markEmailAs, sendReply, changeEmailTags, shareEmail } from '../../actions/emails';
import { Pane } from '../Common/SplitPane';
import styled from 'styled-components';
import cx from 'classnames';
import Collapse from '../Common/Collapse';
import {dateTime, dateTimeLocal, dateTimeShiftedToLocal, formatCargoTotalValues} from '../../core/format';
import Loader from '../Common/Loader';
import ActionVisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import MailFlow from './MailFlow/MailFlow';
import type { IMessageDetails } from './types';
import Socket from '../Socket';
import PubSub from 'pubsub-js';
import CustomTooltip from '../Common/CustomTooltip';
import RaisedButton from '../Common/RaisedButton';
import history from '../../core/history';
import DialogHalfScreen from '../Common/DialogHalfScreen';
import Cargo from '../Cargo';
import CargoPart from '../Cargo/CargoPart';
import ScrollTopWrapper from './ScrollTopWrapper';
import { SeenBy } from './SeenBy';
import Tags from '../Common/Tags/Tags';
import RecognizedIcon from '../Icons/RecognizedIcon';
import Link from '../Link/Link';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import { IconButton, MenuItem } from 'material-ui';
import { PrivateIcon, SharedIcon, typeIcons } from './Icons';
import Search from '../Common/Search';
import {debounceWithoutFirstCall, escapeRegExp, getAttachmentAProps, UUIDToObjectId} from '../../core/utils';
import { uniq } from 'lodash/array';
import EmailSharedDialog from './EmailSharedDialog';
import IconMenu from 'material-ui/IconMenu';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import PositionDialog from '../Vessel/Positions/PositionDialog';
import { sendOpenPosition } from '../../actions/vessel';
import OriginalMessageHtml from "./shared/OriginalMessageHtml";
import ConfirmDialog from "../Common/ConfirmDialog";
import SelectableElement from '../IssueReport/SelectableElement';
import OfferFreightDialog from '../Monitor/OfferFreightDialog';
import CargoAPI from "../../core/api/Cargo";
import { sendOffer } from '../../actions/monitor';
import WithTooltip from '../Common/WithTooltip';
import Vessel from '../../core/api/Vessel';
import AddFormTC from '../Contracts/Timecharter/AddFormTC';
import AddForm from '../Documents/AddForm';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';

type Props = {
  loading: boolean,
  store: { [string]: { } },
  selectedEmail: IMessageDetails,
  emailId: string,
  getEmailDetails: (id: string) => Promise,
  handleClose: () => void,
  sendReply: () => void,
  shareEmail: () => void,
  markAsRead: (emailId: string, threadId: string, {read: boolean, text: string }) => void,
  user: *,
  mailGatesState: { own:any[], company:any[] },
  action: 'add-cargo' | void,
  changeEmailTags: () => void,
  showRecognizedItems: boolean,
  mailFlowProps: {},
  shouldConfirmClose: boolean,
}

type State = {
  hasNewReply: boolean,
  searchQuery: string,
  searchRegExp: RegExp,
  searchMatchesCount: number,
  focusedMatchNumber: number,

};

const TAGS_AUTOCOMPLETE_PROPS = {
  style:{ zIndex: 10, position: "relative" },
}


const EmailHeader = styled((
  {
    onClose,
    className,
    message,
    onCreateCargoClick,
    onSearch,
    search,
    searchRegExp,
    searchMatchesCount,
    focusedMatchNumber,
    onScrollToFoundMatch,
    handleShareOpen,
    anchor,
    openAddSelect,
    closeAddSelect,
    open,
    onCreatePositionClick,
    anchorOffer,
    openCargoOffer,
    handleOpenOffer,
    openCargoOfferSelect,
    closeCargoOfferSelect,
    createOfflineSelect,
    anchorCreateOffline,
    openCreateOfflineSelect,
    closeCreateOfflineSelect,
    handleOpenCreateOfflineContract,
    openMeatballsMenu,
    closeMeatballsMenu,
    meatballsOpen,
    meatballsAnchorEl,
  }) => {
  let searchNav = null;
  if (searchMatchesCount > 1) {
    searchNav = (<div className="search_nav">
      <span>{`${focusedMatchNumber} of ${searchMatchesCount}`}</span>
      <span onClick={onScrollToFoundMatch.bind(this, focusedMatchNumber + 1)} className="material-icons-outlined">expand_more</span>
      <span onClick={onScrollToFoundMatch.bind(this, focusedMatchNumber - 1)} className="material-icons-outlined">expand_less</span>
    </div>);
  }
  const recognizedCargo = message.recognizedItems.filter(item => item?.entityType === "cargo");
  return (<div className={className}>
    <h4><div className={'search'}><Search placeholder={"Enter any text"} handleSearch={onSearch} query={search} searchOnEnter /> {searchNav}</div></h4>
    <div tabIndex={1}>
      {message.predictedType === 'cargo' && message.recognized === false ?
        <RaisedButton primary label={"ADD CARGO"} onClick={onCreateCargoClick} styles={'xs'} style={{ minWidth: "78px" }} labelStyle={{ paddingLeft: "0", paddingRight: "0", fontSize: "11px" }}/> : null}
      {message.predictedType === 'vessel' && message.recognized === false ?
        <RaisedButton primary label={"ADD POSITION"} onClick={onCreatePositionClick} style={{ minWidth: "90px" }} labelStyle={{ paddingLeft: "0", paddingRight: "0", fontSize: "11px" }} styles={'xs'}/> : null}
      {message.predictedType === 'spam' ?
        <RaisedButton primary label={<span className="add_button"/>} onClick={openAddSelect} styles={'small'} style={{ minWidth: "28px", height: "24px" }}/> : null}
      {(message.predictedType === 'cargo' && (message.recognized === true || message.recognizedByUser === true) && recognizedCargo?.length > 0)
        && <RecognizedCargoSelect
          anchor={anchorOffer}
          items={recognizedCargo}
          handleOpen={openCargoOfferSelect}
          handleClose={closeCargoOfferSelect}
          open={openCargoOffer}
          handleOpenOffer={handleOpenOffer}
          message={message}
        />
      }
      <SeenBy message={message}/>
      <Popover
        open={open}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        onRequestClose={closeAddSelect}
        style={{ marginTop: "4px" }}
      >
        <Menu>
          <MenuItem
            onClick={() => {
              onCreateCargoClick();
              closeAddSelect();
            }}
            style={{ fontSize: "13px", lineHeight: "28px", minHeight: "28px", minWidth: "158px" }}
          >
            Add cargo
          </MenuItem>
          <MenuItem
            onClick={() => {
              onCreatePositionClick();
              closeAddSelect();
            }}
            style={{ fontSize: "13px", lineHeight: "28px", minHeight: "28px", minWidth: "158px" }}
          >
            Add position
          </MenuItem>
        </Menu>
      </Popover>
      <IconButton
        onClick={openMeatballsMenu}
        style={{ padding: "0", width: "30px", height: "30px", transform: "rotate(90deg)" }}
      >
        <div className={"icon_wrapper"} style={{ width: "100%", height: "100%" }}><MoreVertIcon /></div>
      </IconButton>
      <Popover
        open={meatballsOpen}
        anchorEl={meatballsAnchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={closeMeatballsMenu}
        style={{ marginTop: "4px", padding: "8px 0" }}
      >
        <MenuItem style={{ fontSize: "13px", minWidth: "160px", lineHeight: "28px", minHeight: "28px" }} primaryText="Share to..." onClick={handleShareOpen}/>
        {(message.predictedType === 'cargo' && (message.recognized === true || message.recognizedByUser === true) && recognizedCargo?.length > 0)
        && <MenuItem
          style={{ fontSize: "13px", minWidth: "160px", lineHeight: "28px", minHeight: "28px" }}
          innerDivStyle={{ padding: "0" }}
        >
          <RecognizedCargoVesselSelect
            anchor={anchorCreateOffline}
            items={recognizedCargo}
            handleOpen={openCreateOfflineSelect}
            handleClose={closeCreateOfflineSelect}
            open={createOfflineSelect}
            handleOpenOffer={handleOpenCreateOfflineContract}
            message={message}
          />
        </MenuItem>}
      </Popover>
      
      <span onClick={onClose} className={cx("icon_wrapper", "material-icons-outlined close-icon")}>close</span>
    </div>
  </div>);
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 47px;
  background: var(--bg-light-grey);
  padding-right: 10px;
  h4 {
    color: var(--text-dark);
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  .close-icon {
    color: var(--icon-default);
    position: relative;
    right:-8px;
    cursor: pointer;
    top: -1px;
  }
  .icon_wrapper:hover {
    border-radius: 4px;
    background: #E6E6E6;
  }
  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  >div >div + div {
    margin-left: 8px;
  }
  .search_nav {
    display: flex;
    align-items: center;
    .material-icons-outlined {
      cursor: pointer;
    }
    color: var(--text-medium);
    >span:first-child {
      min-width: 80px;
      text-align: right;
      margin: 0 8px;
      font-weight: 400;
    }
  }
  .search{
    height: 45px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 12px;
    svg:first-child {
      width: 16px!important;
      height: 16px!important;
    }
    >div:first-child {
      width: 220px!important;
      margin-left: 0!important;
      height: 24px !important;
    }
  }
  .add_button {
    position: relative;
    color: transparent;
    &::after {
      position: absolute;
      top: 4px;
      left: -5px;
      content: "";
      background-color: #fff;
      width: 10px;
      height: 2px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: -1px;
      content: "";
      background-color: #fff;
      width: 2px;
      height: 10px;
    }
  }
`;

const Body = styled.div`
  padding: 12px 20px 0px 20px;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  a {
    text-decoration: underline;
  }
  small {
    font-size: 10px;
  }
  .shared_message_icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    transition: background-color 0.15s ease-in-out;
  }
  .shared_message_icon:hover {
    background-color: #E6E6E6;
  }
  .shared_message_tooltip {
    padding: 10px 12px 8px;
    flex-direction: column;
    justify-content: start;
    width: 275px;
    max-height: 120px;
    overflow: auto;
    .shares_message_container {
      width: 100%;
    }
    .shred_message_name {
      color: #333333;
      font-size: 13px;
      text-transform: none;
      text-overflow: ellipsis;
      min-height: 26px;
      width: 100%;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
    }
  }
  .shared_message_tooltip::after {
    display: none;
  }
  .mail_flow_wrapper {
    padding: 2px 0;
  }

  strong {
    font-weight: 500;
  }
  .highlight {
    scroll-margin-top: 50px;
  }

  pre {
    margin: 0;
    white-space: pre-wrap;
  }
  .attachments-collapse {
    margin-bottom: 48px;
  }

`;

function Whoops({ retry, close }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <ActionVisibilityOff style={{ width: '140px', height: '140px', fill: '#E0E0E0' }}/>
    <div>Message could not be loaded. <a onClick={retry}>Try again</a>&nbsp;&nbsp;&nbsp;<a onClick={close}>Close</a></div>
  </div>
}

const MailAttachments = styled(({ className, attachments = { received: [], sent: [] } }) => {
  return (
    <div className={className}>
    {attachments.received.length ? <h5>RECEIVED</h5> : null }
    <div>{attachments.received.sort((att1, att2) => new Date(att2.addedAt) - new Date(att1.addedAt)).map(attachment => {
      return (<div><div><span className="material-icons-outlined icon">attachment</span><a {...getAttachmentAProps(attachment)}>{attachment.name}</a></div><span className={'date'}>{attachment.addedAt ? dateTimeShiftedToLocal(attachment.addedAt) : null}</span> </div>);
      })}</div>
    {attachments.sent.length ? <h5>SENT</h5> : null }
    <div>{attachments.sent.sort((att1, att2) => new Date(att2.addedAt) - new Date(att1.addedAt)).map(attachment => {
      return (<div><div><span className="material-icons-outlined icon">attachment</span><a {...getAttachmentAProps(attachment)}>{attachment.name}</a></div><span className={'date'}>{attachment.addedAt ? dateTimeShiftedToLocal(attachment.addedAt) : null}</span></div>);
      })}</div>
  </div>);
})`
h5 {
  font-size: 12px;
  margin: 5px 24px;
  line-height: 24px;
}
.icon {
  font-size: 20px;
  transform: rotate(-45deg);
  margin-right: 4px;
  color: var(--text-medium);
}
  >div >div {
    & + div {
      margin-top: 8px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    >div {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .date {
      margin-left: 8px;
      color: var(--text-medium, #787878);
      font-size: 12px;
    }
  }
  a {
    text-decoration: underline;
    word-break: break-all;
  }
`;

const NewReplyBar = styled(({ onClose, onView, className, visible }) => {
  if (!visible) {
    return null;
  }
  return <div className={className}>
    <div>You have received a new reply to this message <a onClick={onView}>view</a></div>
    <span onClick={onClose} className="material-icons-outlined">close</span>
  </div>;
})`
height: 48px;
position: absolute;
color: white;
background-color: rgba(0,0,0,0.8);
display: flex;
align-items: center;
justify-content: center;
width: 100%;
z-index: 105;
top:48px;
left:0;
font-size: 12px;
font-weight: 500;

> span {
  color: white;
  position: absolute;
  right: 11px;
  top:12px;
  cursor:pointer;
}
a {
  text-decoration: underline;
}
`;
function linkByType(item) {
  if (item.entityType === 'cargo') {
    return `/main-deck/${item.entityId}`;
  }
  if (item.entityType === 'tc') {
    return `/main-deck/${item.entityId}/-/?leftPaneTab=tc`;
  }
  if (item.entityType === 'vessel' || item.entityType === 'sales') {
    return `/main-deck/-/${item.vesselId}`;
  }
}



class EmailDetails extends Component<Props, State> {
  static contextTypes = {
    showMessage: PropTypes.func,
  };
  state = {
    hasNewReply: false,
    recItemsOpen: false,
    shareOpened: false,
    addSelectOpen: false,
    meatballsOpen: false,
    recCargoOpen: false,
    offerOpened: false,
    createOfflineSelect: false,
    createOfflineCargoContract: false,
    isOfferStateChanged: false,
  };
  originalMessageCollapse: Collapse = null;
  mailFlow: MailFlow = null;
  mailFlowCollapse: Collapse = null;
  attachmentsCollapse: Collapse = null;
  readTimer = null;

  static defaultProps = {
    showRecognizedItems: true,
    mailFlowProps: {},
  }
  componentDidMount() {
    if (this.props.emailId) {
      this.loadMessage(null, this.props.emailId);
    }
    PubSub.subscribe('mailer:thread', this.socketHandler);
    Socket.joinRoom('mailer:thread', { key: this.props.emailId });
    window.addEventListener('message', this.onMessage);
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.socketHandler);
    window.removeEventListener('message', this.onMessage);
    Socket.leaveRoom('mailer:thread', { key: this.props.emailId });

    if (this.readTimer) {
      clearTimeout(this.readTimer);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.emailId !== this.props.emailId) {
      Socket.leaveRoom('mailer:thread', { key: this.props.emailId });
      if (this.state.searchQuery) {
        this.setState({ searchQuery: "", searchRegExp: null, focusedMatchNumber:0, searchMatchesCount: 0 });
      }
      this.loadMessage(null, nextProps.emailId);
      this.closeNewReplyBar();
      Socket.joinRoom('mailer:thread', { key: nextProps.emailId });
    }
  }

  socketHandler = (topic, data) => {
    if (data.threadId === this.props.emailId) {
      const threadIds = this.props.selectedEmail.nestedMails.map(nm => nm.id);
      if (data.mails.filter(m => !m.sentByClient && threadIds.indexOf(m.id) === -1 && m.id !== data.threadId).length) {
        this.openNewReplyBar();
      }
    }
  }

  loadMessage = (e, id = this.props.emailId, silent = false) => {
    return this.props.getEmailDetails(id, silent);
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const message: IMessageDetails = this.props.selectedEmail;
    if (!message) {
      return;
    }
    const nestedMails = message.nestedMails;
    if ((message !== prevProps.selectedEmail) || (prevProps.loading && !this.props.loading) ) {
      const unreadReply = nestedMails.find(m => !m.read);
      if (nestedMails.length && (nestedMails.length !== prevProps.selectedEmail?.nestedMails?.length || message.id !== prevProps.selectedEmail?.id)) {
        setTimeout(() => {
          setTimeout(() => {
            this.mailFlow.scrollToReply(nestedMails[nestedMails.length - 1].refNo);
          });
        }, 200);
      } else if (prevProps.loading && !this.props.loading) {
        setTimeout(() => {
         setTimeout(() => {
           this.mailFlow.scrollToReply(nestedMails[nestedMails.length - 1]?.refNo);
         })
        }, 200);
      }
      if (!message.read || unreadReply) {
        clearTimeout(this.readTimer);
        this.readTimer = setTimeout(this.markAsRead, 1000);
        //setTimeout(this.openNewReplyBar, 2000);
      }
    }
  }


  refOriginalMessageCollapse = (el: Collapse) => {
    this.originalMessageCollapse = el;
  }

  refMailFlow = (el: MailFlow) => {
    this.mailFlow = el;
  }
  refMailFlowCollapse = (el: Collapse) => {
    this.mailFlowCollapse = el;
  }
  refAttachmentsCollapse = (el: Collapse) => {
    this.attachmentsCollapse = el;
  }

  scrollToOriginalMessage = () => {
    this.originalMessageCollapse?.show(null, ()=> document.getElementById(`mail-details-${this.props.selectedEmail.refNo}`)?.scrollIntoView());
  }

  startReply = (e, replyToAll = false) => {
    this.mailFlowCollapse.show(null, () => this.mailFlow.handleStartReply({ ...this.props.selectedEmail, nestedMails: [] }, e, replyToAll));
  }
  startForward = (e) => {
    this.mailFlowCollapse.show(null, () => this.mailFlow.handleStartForward({ ...this.props.selectedEmail, nestedMails: [] }, e));
  }

  closeNewReplyBar =() => {
    this.setState({ hasNewReply: false });
  }

  openNewReplyBar = () => {
    this.setState({ hasNewReply: true });
  }

  reloadMessage = async () => {
   await this.loadMessage(null, this.props.emailId, true);
   this.setState({ hasNewReply: false });
  }

  openCreateCargo = () => {
    history.push('/$1/$2/add-cargo/$4');
  }
  openCreatePosition = () => {
    history.push('/$1/$2/add-position/$4');
  }
  closeCreateCargo = () => {
    history.push('/$1/$2/-/$4');
    this.reloadMessage();
  }
  markArUnread = async (e, threadId) => {
    let text = this.props.selectedEmail.text;
    const nestedMails = this.props.selectedEmail.nestedMails;
    let threadIds;
     if (threadId) {
       threadIds = [];
       const threadIndex = nestedMails.findIndex(t => t.id === threadId);
       const thread = nestedMails[threadIndex];
       for (let i = threadIndex; i < nestedMails.length; i++) {
         const nm = nestedMails[i];
         if (!nm.sentByClient) {
           threadIds.push(nm.id);
         }
       }
      if (thread) {
        text = thread.text;
      }
    }
    await this.props.markAsRead(this.props.selectedEmail.id, threadIds, { read: false, text });
    history.push('/$1/$2/-/-/-/---');
  }

  markAsRead = async () => {
    let text = this.props.selectedEmail.text;
    const nestedMails = this.props.selectedEmail.nestedMails;
    if (nestedMails.length) {
      text = nestedMails[nestedMails.length - 1].text;
    }
    await this.props.markAsRead(this.props.selectedEmail.id, null, { read: true, text });
  }
  handleSaveTags = async (id, tagsChanges) => {
    this.props.changeEmailTags(this.props.selectedEmail.id, tagsChanges);
  }
  openMeatballsMenu = (ev) => {
    ev.preventDefault();
    this.setState({
      meatballsOpen: true,
      meatballsAnchorEl: ev.currentTarget,
    });
    this.closeCargoSelect();
  }
  openRecSelect = (ev) => {
    ev.preventDefault();
    this.setState({
      recItemsOpen: true,
      anchorEl: ev.currentTarget,
    });
  }
  openAddSelect = (ev) => {
    ev.preventDefault();
    this.setState({
      addSelectOpen: true,
      anchorAddEl: ev.currentTarget,
    });
  }
  openCargoSelect = (ev) => {
    ev.preventDefault();
    this.setState({
      recCargoOpen: true,
      anchorOfferEl: ev.currentTarget,
    });
    this.closeCreateOfflineSelect();
  }
  openCreateOfflineSelect = (ev) => {
    ev.preventDefault();
    this.setState({
      createOfflineSelect: true,
      anchorCreateOffline: ev.currentTarget,
    });
    
  }

  handleAddPosition = (position) => {
    this.props.sendOpenPosition(position, this.props.user).then((res) => {
      if (res.status === 200) {
        history.push(`/main-deck/-/${position.vessel._id}/`);
      }
    });
    // console.log('/main-deck/-/${id}/');
  }

  closeMeatballsMenu = () => {
    this.setState({
      meatballsOpen: false,
      meatballsAnchorEl: null,
    });
  }
  closeRecSelect = () => {
    this.setState({
      recItemsOpen: false,
      anchorEl: null,
    });
  }
  closeCargoSelect = () => {
    this.setState({
      recCargoOpen: false,
      anchorOfferEl: null,
    });
  }
  closeCreateOfflineSelect = () => {
    this.setState({
      createOfflineSelect: false,
      anchorCreateOffline: null,
      meatballsOpen: false,
      meatballsAnchorEl: null,
    });
  }

  handleSendOffer = async (offer) => {
    try {
      const preparedOffer = { ...offer, offerFromExchange: true };
      const request = sendOffer(preparedOffer);
      const result = await request.promise;
        this.context.showMessage({
          message: result.data.message,
        });
        this.setState({ offerOpened: false });
        this.reloadMessage();
    } catch (e) {
      this.context.showMessage({
        level: "error",
        message: e?.message?.errors?.[0]?.messages?.[0],
      });
    }
  };

  handleOpenOffer = async (cargo) => {
    if (this.state.cargoForOffer && this.state.cargoForOffer._id === cargo.entityId && this.state.offerOpened) {
      return;
    }
    if (this.state.offerOpened) {
      this.closeCargoSelect();
      this.setState({
        offerOpened: false,
        cargoForOffer: null,
      });
    }
    try {
      const res = await CargoAPI.getMonitorCargoById(cargo.entityId);
      this.setState({
        offerOpened: true,
        cargoForOffer: res.data,
        recCargoOpen: false,
      });
    } catch (e) {
      console.error(e.message);
    }
  }
  handleOpenCreateOfflineContract = async (entity) => {
    if (this.state.cargoForOfflineContract
      && this.state.cargoForOfflineContract._id === entity.entityId
      && this.state.createOfflineCargoContract) {
      return;
    }
    if (this.state.createOfflineCargoContract) {
      this.setState({
        createOfflineCargoContract: false,
        createOfflineSelect: false,
        meatballsOpen: false,
      });
    }
    try {
      if (entity.entityType === "cargo") {
        const res = await CargoAPI.getMonitorCargoById(entity.entityId);
        this.setState({
          createOfflineCargoContract: true,
          cargoForOfflineContract: res.data,
          createOfflineSelect: false,
          meatballsOpen: false,
        });
      }
    } catch (e) {
      console.error(e.message);
    }
  }
  getOfferState = (isChanged) => {
    this.setState({ isOfferStateChanged: !!isChanged });
  }
  handleCloseOffer = () => {
    if (this.state.isOfferStateChanged) {
      this.setState({
        confirmDialog: (<ConfirmDialog
          title="DATA IS NOT SAVED"
          open
          handleClose={(confirm) => {
            this.setState({ confirmDialog: undefined });
            if (confirm) {
              this.setState({
                offerOpened: false,
                cargoForOffer: null,
                isOfferStateChanged: false,
              });
            }
          }}
        >
          <span>Are you sure you want to leave this page?</span>
        </ConfirmDialog>),
      });
    } else {
      this.setState({
        offerOpened: false,
        cargoForOffer: null,
        isOfferStateChanged: false,
      });
    }
  }
  handleCloseCreateOfflineContract = () => {
    this.setState({
      confirmDialog: (<ConfirmDialog
        title="DATA IS NOT SAVED"
        open
        handleClose={(confirm) => {
          this.setState({ confirmDialog: undefined });
          if (confirm) {
            this.setState({
              createOfflineCargoContract: false,
              cargoForOfflineContract: null,
            });
          }
        }}
      >
        <span>Are you sure you want to leave this page?</span>
      </ConfirmDialog>),
    });
  }
  handleSuccessOfflineContract = () => {
    this.setState({
      createOfflineCargoContract: false,
      cargoForOfflineContract: null,
    });
    this.reloadMessage();
  }
  getDomId(){
    return `mail-details-${this.props.selectedEmail?.refNo || this.props.emailId}`;
  }
  getScrollContainer() {
    return document.querySelector(`#${this.getDomId()} .scroll-container`);
  }

  findHighlightedElements() {
    return [...document.querySelectorAll(`#${this.getDomId()} .highlight`)];
  }
  afterSearch = debounceWithoutFirstCall(async () => {
    this.scrollBlocked = null;
    const matchElList = this.findHighlightedElements();
    if (!matchElList.length) {
      if (this.state.searchMatchesCount) {
        this.setState({ searchMatchesCount: 0, focusedMatchNumber: 0 });
      }
      return;
    }
    const refs = uniq(matchElList.map(el => el.getAttribute('data-refno')));
    const openPromises = [];
    for (let i = 0; i < refs.length; i++) {
      const ref = refs[i];
      openPromises.push(this.mailFlow.replyCollapses[ref]?.show());
    }
    await Promise.all(openPromises);
    matchElList[0].scrollIntoView();
    if (matchElList.length > 1) {
      matchElList[0].classList.add('active');
    }
    this.setState({ searchMatchesCount: matchElList.length, focusedMatchNumber: 1 });
    document.querySelector(`#${this.getDomId()} .search input`).focus();
  })

  handleSearch = (query) => {
    if (!query || !query.trim) {
      if (!this.state.searchQuery) {
        return;
      }
      return this.setState({ searchQuery: '', searchRegExp: null, searchMatchesCount: 0, focusedMatchNumber: 0 });
    }
    if (this.state.searchQuery === query) {
      return;
    }
    this.scrollBlocked = this.getScrollContainer()?.scrollTop;
    this.setState({ searchQuery: query, searchRegExp: escapeRegExp(query, 'igm') }, () => {
      this.getScrollContainer().scrollTop = this.scrollBlocked;
      setTimeout( () => {
        this.getScrollContainer().scrollTop = this.scrollBlocked;
      });
    });
  }

  scrollToFoundMatch = async (number) => {
    const matchElList = this.findHighlightedElements();
    if (number < 1) {
      number = matchElList.length;
    } else if (number > matchElList.length){
      number = 1;
    }
    const el = this.findHighlightedElements()[number - 1];
    if (!el) {
      return;
    }
    await this.mailFlow.replyCollapses[el.getAttribute('data-refno')]?.show();
    el.scrollIntoView();
    matchElList.forEach(el => el.classList.remove('active'));
    el.classList.add('active');
    this.setState({ focusedMatchNumber: number });
  }
  onMessage = (e) => {
    switch (e.data.type) {
      case 'search:found':
        if (this.scrollBlocked != null) {
          this.getScrollContainer().scrollTop = this.scrollBlocked;
          setTimeout( () => {
            this.getScrollContainer().scrollTop = this.scrollBlocked;
          });
        }
        break;
      case 'search:completed':
        this.afterSearch();
        break;
    }
  }

  onScroll = (e, container) => {
    if (this.scrollBlocked != null) {
      this.getScrollContainer().scrollTop = this.scrollBlocked;
    }
  }

  handleShareOpen = () => {
    this.setState({ shareOpened: true });
    this.closeMeatballsMenu();
  }

  handleShareClose = () => {
    this.setState({ shareOpened: false });
  }

  handleShareSave = async (visibleTo) => {
    const res = await this.props.shareEmail(this.props.emailId, { visibleTo });
    if (res.data) {
      this.setState({ shareOpened: false });
    }
    return res;
  }
  closeAddSelect = () => {
    this.setState({
      addSelectOpen: false,
      anchorAddEl: null,
    });
  }

  handleClose = () => {
    if (this.mailFlow.historyBlocked && this.props.shouldConfirmClose) {
      this.setState({
        confirmDialog: (<ConfirmDialog
          title="DATA IS NOT SAVED"
          open
          handleClose={(confirm) => {
            this.setState({ confirmDialog: undefined });
            if (confirm) {
              this.props.handleClose();
            }
          }}
        >
          <span>Are you sure you want to leave this page?</span>
        </ConfirmDialog>),
      });
    } else {
      this.props.handleClose();
    }
  }
  afterOriginalMessageRender = () => {
    const message: IMessageDetails = this.props.selectedEmail;
    if (!message) {
      return;
    }
    const nestedMails = message.nestedMails;
    if (nestedMails.length) {
      setTimeout(() => {
        setTimeout(() => {
          this.mailFlow.scrollToReply(nestedMails[nestedMails.length - 1].refNo);
        });
      });
  }
  }

  render() {
    const message = this.props.selectedEmail;
    const user = this.props.user;
    if (this.props.loading) {
      return <Loader/>;
    }
    if (!message) {
      return <Whoops retry={this.loadMessage} close={this.handleClose} />;
    }
    const attachmentsCount = message.attachments.received.length + message.attachments.sent.length;
    const isMailGateErrored = this.props.mailGatesState?.own?.length;
    const erroredGates = this.props.mailGatesState?.own;
    const visibleTo = (message.visibleTo || []).filter(member => member._id !== user._id);
    const isPrivate = message.isPrivate;

    return (
      
          <Pane
            id={this.getDomId()}
            key={message.id}
            bodyProps={{style: {height: '100%'}}}
            headerProps={{
              useBorder: true,
              children: <EmailHeader
                searchRegExp={this.state.searchRegExp} search={this.state.searchQuery}
                searchMatchesCount={this.state.searchMatchesCount} focusedMatchNumber={this.state.focusedMatchNumber}
                message={message}
                onSearch={this.handleSearch} onScrollToFoundMatch={this.scrollToFoundMatch}
                onClose={this.handleClose}
                onCreateCargoClick={this.openCreateCargo}
                onCreatePositionClick={this.openCreatePosition}
                anchor={this.state.anchorAddEl}
                openAddSelect={this.openAddSelect}
                closeAddSelect={this.closeAddSelect}
                open={this.state.addSelectOpen}
                handleShareOpen={this.handleShareOpen}
                openCargoOfferSelect={this.openCargoSelect}
                closeCargoOfferSelect={this.closeCargoSelect}
                anchorOffer={this.state.anchorOfferEl}
                openCargoOffer={this.state.recCargoOpen}
                handleOpenOffer={this.handleOpenOffer}
                createOfflineSelect={this.state.createOfflineSelect}
                anchorCreateOffline={this.state.anchorCreateOffline}
                openCreateOfflineSelect={this.openCreateOfflineSelect}
                closeCreateOfflineSelect={this.closeCreateOfflineSelect}
                handleOpenCreateOfflineContract={this.handleOpenCreateOfflineContract}
                openMeatballsMenu={this.openMeatballsMenu}
                closeMeatballsMenu={this.closeMeatballsMenu}
                meatballsOpen={this.state.meatballsOpen}
                meatballsAnchorEl={this.state.meatballsAnchorEl}
              />,
            }}
            scrollable
          > <NewReplyBar visible={this.state.hasNewReply} onView={this.reloadMessage} onClose={this.closeNewReplyBar}/>
            <ScrollTopWrapper onScroll={this.onScroll}>
              <Body >
              <div className="tags_wrapper">
                <Tags handleSave={this.handleSaveTags} entityId={message.id} tags={message.tags} opened={message.tags.length > 0} entity="cargoRequest" autocompleteProps={TAGS_AUTOCOMPLETE_PROPS} />
              </div>
            <Collapse
              defaultOpen
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isPrivate ? <PrivateIcon/> : (
                    visibleTo.length > 0 ? <CustomTooltip
                        horizontalAlign={'right'}
                        dark={false}
                        className="shared_message_tooltip"
                        tooltip={
                          visibleTo.map(m => (
                            <div className="shred_message_name">{`${!m.name && !m.surname ? m.email : (m.name || "") + " " + (m.surname || "")}`}</div>
                          ))
                        }
                      >
                        <div className="shared_message_icon">
                          <SharedIcon style={{ marginRight: "0" }} />
                        </div>
                      </CustomTooltip> : <SharedIcon style={{ marginRight: "0" }} />
                  ) }
                  <span>ORIGINAL MESSAGE</span>
                </div>}
              additionals={
                (message.recognized || message.recognizedByUser) && this.props.showRecognizedItems && message.recognizedItems.length > 0 ?
                  message.recognizedItems.length > 1 ?
                  <RecognizedTypeSelect anchor={this.state.anchorEl} items={message.recognizedItems} handleOpen={this.openRecSelect} handleClose={this.closeRecSelect} open={this.state.recItemsOpen}/>
                  : <Link to={linkByType(message.recognizedItems[0])}><RecognizedIcon/></Link>
                : null}
              innerRef={this.refOriginalMessageCollapse}
            >
              <SelectableElement entity="mails" entityId={message.id} type="exchange ref" id={message.refNo}>
                <OriginalMessageHtml
                  message={message}
                  searchRegExp={this.state.searchRegExp}
                  onReplyClick={this.startReply}
                  onForwardClick={this.startForward}
                  onUnreadClick={this.markArUnread}
                  search={this.state.searchQuery}
                  afterIframeRender={this.afterOriginalMessageRender}
                />
              </SelectableElement>
            </Collapse>
            <Collapse defaultOpen title={`MAIL FLOW ${message.nestedMails?.length ? `(${message.nestedMails.length})` : ''} `} innerRef={this.refMailFlowCollapse}>
              <div className="mail_flow_wrapper">
                <MailFlow
                  cargoId={this.props.cargoId}
                  vesselId={this.props.vesselId}
                  message={message}
                  user={this.props.user}
                  sendReply={this.props.sendReply}
                  scrollToOriginalMessage={this.scrollToOriginalMessage}
                  innerRef={this.refMailFlow}
                  markAsUnread={this.markArUnread}
                  searchQuery={this.state.searchQuery}
                  searchRegExp={this.state.searchRegExp}
                  mailGatesState={this.props.mailGatesState}
                  {...this.props.mailFlowProps}
                  scrollingContainer=".scroll-container"
                />
              </div>
            </Collapse>
                {attachmentsCount ? (
                  <Collapse defaultOpen={false} title={`ATTACHMENTS (${attachmentsCount})`}
                            innerRef={this.refAttachmentsCollapse}
                            className={'attachments-collapse'}
                  >
                    <div className="mail_attachments_wrapper">
                      <MailAttachments attachments={message.attachments}/>
                    </div>
                  </Collapse>) : null}
                {this.state.shareOpened ? (
                  <EmailSharedDialog opened handleClose={this.handleShareClose} handleSave={this.handleShareSave}
                                     user={user} visibleTo={visibleTo} handleShareMember={this.handleShareMember}/>
                ) : null}
                {this.props.action === 'add-cargo' ? (
                  <DialogHalfScreen open position="left" menuOpen={this.props.menuOpen}>
                    <CargoPart keyId="4">
                      <Cargo
                        mailFrom={message.from?.[0]}
                        mailId={UUIDToObjectId(message.id)}
                        handleClose={this.closeCreateCargo}
                        actionType="create"
                        myCargoDisabled
                        withoutRedirect
                      />
                    </CargoPart>
                  </DialogHalfScreen>
                ) : null}
                {this.props.action === 'add-position' ? (
                  <PositionDialog mailId={UUIDToObjectId(message.id)} handleClose={this.closeCreateCargo} handleSendNewPosition={this.handleAddPosition}/>
                ) : null}
                {this.state.offerOpened ? (
                  
                  <OfferFreightDialog 
                    handleSend={this.handleSendOffer}
                    user={user}
                    handleCancel={null}
                    vessel={null}
                    bid={{}}
                    handleClose={this.handleCloseOffer}
                    cargo={this.state.cargoForOffer}
                    position="left"
                    getOfferState={this.getOfferState}
                    menuOpen={this.props.menuOpen}
                  />
                ) : null}
                {this.state.createOfflineCargoContract ? <DialogHalfScreen open position="left" menuOpen={this.props.menuOpen}>
                  <AddForm
                    fromExchange
                    title={() => 'ADD NEW CONTRACT'}
                    handleClose={this.handleCloseCreateOfflineContract}
                    type={"voyage"}
                    actionType="create"
                    cargo={this.state.cargoForOfflineContract}
                    mailId={UUIDToObjectId(message.id)}
                    handleSuccess={this.handleSuccessOfflineContract}
                  />
                </DialogHalfScreen>
                 : null}
              </Body>
            </ScrollTopWrapper>
            {this.state.confirmDialog}
          </Pane>
    );
  }
}

function RecognizedTypeSelect({ open, anchor, items, handleOpen, handleClose }) {
  const recognizedEntityType = (c) => {
    if (c?.entityType === "tc") {
      return (
        <MenuItem style={{ fontSize: "13px", lineHeight: "28px", minHeight: "28px" }}>
          {typeIcons[c?.entityType]} Ref: {c?.refNo}
        </MenuItem>
      );
    }
    if (c?.entityType === "cargo") {
      return (
        <MenuItem style={{ fontSize: "13px", lineHeight: "28px", minHeight: "28px" }}>
          {typeIcons[c?.entityType]} {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
        </MenuItem>
      );
    }
    if (c?.entityType === "vessel" || c?.entityType === "sales") {
      return (
        <MenuItem style={{ fontSize: "13px", lineHeight: "28px", minHeight: "28px" }}>
          {typeIcons[c?.entityType]} M/V {c?.vesselName}
        </MenuItem>
      );
    }
  };

  return (
    <div>
      <span onClick={handleOpen} style={{ cursor: 'pointer', display: 'flex', flex: 1, alignItems: 'center' }}>
        <RecognizedIcon />
      </span>
      <Popover
        open={open}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={handleClose}
      >
        <Menu>
          {
            items.map(c => <Link key={c?.entityId} to={linkByType(c)}>
              {recognizedEntityType(c)}
            </Link>)
          }
        </Menu>
      </Popover>
    </div>
  )
}

const MenuIcon = styled.div`
  margin-right: 4px;
  svg {
    width: 18px !important;
    height: 18px !important;
  }
`;

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  info: {
    flexShrink: '0',
    cursor: 'pointer',
    marginLeft: '6px',
  },
  select: {
    maxWidth: 'calc(100% - 24px)',
  },
  separator: {
    fontSize: '11px',
    fontWeight: '500',
    padding: '0',
    margin: '4px 12px',
    color: 'var(--text-medium)',
    pointerEvents: 'none',
    lineHeight: '24px',
  },
  menuItemStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "16px",
    minHeight: "48px",
    borderBottom: '1px solid var(--stroke-light-gray2)',
  },
  addButton: {
    color: "#4380C7",
    fontSize: "13px",
    fontWeight: "500",
  },
  disabled: {
    opacity: '0.7',
    pointerEvents: 'none',
  },
};
const OfferFreightButton = ({ handleClick, style, title }) => (
  <RaisedButton
    primary
    label={title || "Offer freight"}
    styles="xs"
    style={{
      borderRadius: '15px',
      ...style,
    }}
    buttonStyle={{
      borderRadius: '15px',
    }}
    labelStyle={{
      paddingLeft: '8px',
      paddingRight: '8px',
      fontFamily: 'Roboto Condensed',
      fontSize: '11px',
      fontWeight: '700',
      webkitFontSmoothing: 'antialiased',
      textTransform: 'uppercase',
    }}
    onClick={handleClick}
  />
);

function RecognizedCargoVesselSelect({ open, anchor, items, handleOpen, handleClose, handleOpenOffer, message }) {
  const offlineContractRenderer = (c) => {
    return (
      <Link onClick={() => { handleClose(); window.open(`/contracts/${c?.contractId}?contractType=voyage`, "_blank"); }}>
        <MenuItem innerDivStyle={styles.menuItemStyle}>
          <MenuIcon>
            {typeIcons.offlineDocument}
          </MenuIcon>
          <div>
            <div>
              {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
            </div>
            <div>
              {formatCargoTotalValues(c?.contractTotalValues)}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {c?.contractRefNo}
          </div>
        </MenuItem>
      </Link>
    );
  };
  const recognizedContractRenderer = (c) => {
    const lastFixed = c.offers?.[c?.offers?.findLastIndex((offer) => offer.status === "fix")];
    return (
      <Link onClick={() => { handleClose(); window.open(`/contracts/${lastFixed.contract?.contractId}?contractType=voyage`, "_blank"); }}>
        <MenuItem innerDivStyle={styles.menuItemStyle}>
          <MenuIcon>
            {typeIcons.document}
          </MenuIcon>
          <div>
            <div>
              {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
            </div>
            <div>
              {formatCargoTotalValues(lastFixed.contract?.contractTotalValues)}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {lastFixed.contract?.contractRefNo}
          </div>
        </MenuItem>
      </Link>
    );
  };

  const recognizedCargoRenderer = (c) => {
    return (
      <MenuItem innerDivStyle={styles.menuItemStyle} onClick={() => { handleOpenOffer(c); }}>
        <MenuIcon>
          {message.recognizedByUser ? typeIcons.userCargo : typeIcons[c?.entityType]}
        </MenuIcon>
        <div>
          <div>
            {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
          </div>
          <div>
            {formatCargoTotalValues(c?.totalValues)}
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {c?.refNo}
        </div>
      </MenuItem>
    );
  };

  const sortedItems = items.reduce((acc, item) => {
    if (item.offlineContracts && item.offlineContracts?.length) {
      const itemWithoutContracts = { ...item };
      delete itemWithoutContracts.offlineContracts;
      const preparedOfflineContracts = item.offlineContracts.map(contract => ({ ...itemWithoutContracts, ...contract }));
      acc.offlineContracts.push(...preparedOfflineContracts);
    }
    const lastFixed = item.offers?.[item?.offers?.findLastIndex((offer) => offer.status === "fix")];
    if (item.offers && item.offers?.length && lastFixed) {
      acc.contracts.push(item);
      acc.readyToOffer.push(item);
    } else {
      acc.readyToOffer.push(item);
    }
    return acc;
  }, { readyToOffer: [], contracts: [], offlineContracts: [] });

  return (
    <div>
      <span
        style={{ cursor: 'pointer', display: 'flex', flex: 1, alignItems: 'center', justifyContent: "space-between", padding: "0 16px" }}
        onClick={(sortedItems.readyToOffer.length === 1 && !sortedItems.offlineContracts.length && !sortedItems.contracts.length) ? () => handleOpenOffer(items[0]) : handleOpen}
      >
        <span>Move to Contract</span>
        {(sortedItems.readyToOffer.length > 1
          || sortedItems.offlineContracts.length > 0
          || sortedItems.offlineContracts.length > 0)
          && <span><ArrowUp style={{ transform: "rotate(90deg)" }}/></span>}
      </span>
      <Popover
        open={open}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={handleClose}
      >
        <Menu maxHeight={400} width={320}>
          {sortedItems.readyToOffer.map(c => recognizedCargoRenderer(c))}
          {(sortedItems.contracts.length || sortedItems.offlineContracts.length) && <div style={styles.separator}>Linked Contracts</div>}
          {sortedItems.contracts.map(c => recognizedContractRenderer(c))}
          {sortedItems.offlineContracts.map(c => offlineContractRenderer(c))}
        </Menu>
      </Popover>
    </div>
  );
}

function RecognizedCargoSelect({ open, anchor, items, handleOpen, handleClose, handleOpenOffer, message }) {
  const offlineContractRenderer = (c) => {
    return (
      <Link onClick={() => { handleClose(); window.open(`/contracts/${c?.contractId}?contractType=voyage`, "_blank"); }}>
        <MenuItem innerDivStyle={styles.menuItemStyle}>
          <MenuIcon>
            {typeIcons.offlineDocument}
          </MenuIcon>
          <div>
            <div>
              {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
            </div>
            <div>
              {formatCargoTotalValues(c?.contractTotalValues)}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {c?.contractRefNo}
          </div>
        </MenuItem>
      </Link>
    );
};
  const recognizedContractRenderer = (c) => {
    const lastFixed = c.offers?.[c?.offers?.findLastIndex((offer) => offer.status === "fix")];
    return (
      <Link onClick={() => { handleClose(); window.open(`/contracts/${lastFixed.contract?.contractId}?contractType=voyage`, "_blank"); }} >
        <MenuItem innerDivStyle={styles.menuItemStyle}>
          <MenuIcon>
            {typeIcons.document}
          </MenuIcon>
          <div>
            <div>
              {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
            </div>
            <div>
              {formatCargoTotalValues(lastFixed.contract?.contractTotalValues)}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {lastFixed.contract?.contractRefNo}
          </div>
        </MenuItem>
      </Link>
    );
  };
  const recognizedTradeRenderer = (c) => {
    return (
      <Link onClick={() => { handleClose(); window.open(`/my/cargo/${c?.refNo}`, "_blank"); }}>
        <MenuItem innerDivStyle={styles.menuItemStyle}>
          <MenuIcon>
            {message.recognizedByUser ? typeIcons.userCargo : c.expired ? typeIcons.expiredCargo : typeIcons[c?.entityType]}
          </MenuIcon>
          <div>
            <div>
              {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
            </div>
            <div>
              {formatCargoTotalValues(c?.totalValues)}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {c?.refNo}
          </div>
        </MenuItem>
      </Link>
    );
  };

  const recognizedOfferRenderer = (c) => {
    return (
      <MenuItem onClick={() => c.expired ? null : handleOpenOffer(c)} innerDivStyle={c.expired ? { ...styles.menuItemStyle, ...styles.disabled } : styles.menuItemStyle}>
        <MenuIcon>
          {message.recognizedByUser ? typeIcons.userCargo : c.expired ? typeIcons.expiredCargo : typeIcons[c?.entityType]}
        </MenuIcon>
        <div>
          <div>
            {c?.cargoNames[0]}{c?.cargoNames.length > 1 && <span style={{ color: "#4380C7" }}>+{c?.cargoNames.length - 1}</span>}
          </div>
          <div>
            {formatCargoTotalValues(c?.totalValues)}
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {c?.refNo}
        </div>
      </MenuItem>
    );
  };
    
  const sortedItems = items.reduce((acc, item) => {
    if (item.offlineContracts && item.offlineContracts?.length) {
      const itemWithoutContracts = { ...item };
      delete itemWithoutContracts.offlineContracts;
      const preparedOfflineContracts = item.offlineContracts.map(contract => ({ ...itemWithoutContracts, ...contract }));
      acc.offlineContracts.push(...preparedOfflineContracts);
    }
    const lastFixed = item.offers?.[item?.offers?.findLastIndex((offer) => offer.status === "fix")];
    if (item.offers && item.offers?.length && lastFixed) {
      acc.contracts.push(item);
    } else if (item.offers && !lastFixed) {
      acc.trade.push(item);
      acc.readyToOffer.push(item);
    } else {
      acc.readyToOffer.push(item);
    }
    return acc;
  }, { readyToOffer: [], trade: [], contracts: [], offlineContracts: [] });
  return (
    <div>
      <span style={{ cursor: 'pointer', display: 'flex', flex: 1, alignItems: 'center' }}>
        {(items.length === 1
        && !sortedItems.offlineContracts.length
        && (sortedItems.contracts.length === 1 || (sortedItems.readyToOffer.length === 1 && items[0].expired))) ?
          <WithTooltip
            centerArrow
            offset={{ x: 40 }}
            tip={() => <div style={{ maxWidth: "240px" }} className={cx('tooltip-default', 'dark')}>
              <div style={{ marginBottom: "10px" }}>
                {sortedItems.readyToOffer.length === 1 && items[0].expired ?
                  "You can’t Offer Freight on This Cargo Request, because it is expired"
                  : "You can’t Offer Freight on This Cargo Request, because it has a Contract" } 
              </div>
              { sortedItems.contracts.length === 1 && <div>
                <Link style={{ textDecoration: "underline" }} target="_blank" to={`/contracts/${sortedItems.contracts[0].offers[sortedItems.contracts[0].offers.length - 1].contract?.contractId}?contractType=voyage`}>View Contract</Link>
              </div>}
            </div>}
          >
            <OfferFreightButton
              title={items.length === 1 && (sortedItems.contracts.length) ? "Trade flow" : "Offer freight"}
              style={{
                opacity: '0.5',
                pointerEvents: 'none',
              }}
            />
          </WithTooltip>
        :
          <OfferFreightButton
            title={items?.length && (sortedItems.offlineContracts.length || sortedItems.contracts.length || sortedItems.trade.length)
              ? "Trade flow"
              : "Offer freight"
            }
            handleClick={(items?.length === 1
              && !sortedItems.offlineContracts.length
              && !sortedItems.contracts.length
              && !sortedItems.trade.length) ?
                () => handleOpenOffer(items[0])
              : handleOpen
            }
          />
        }
      </span>
      <Popover
        open={open}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        onRequestClose={handleClose}
        style={{ marginTop: "4px" }}
      >
        <Menu maxHeight={400} width={320}>
          {sortedItems.readyToOffer.length && <div style={styles.separator}>Ready for Offer</div>}
          {sortedItems.readyToOffer.map(c => recognizedOfferRenderer(c))}
          {sortedItems.trade.length && <div style={styles.separator}>Trade</div>}
          {sortedItems.trade.map(c => recognizedTradeRenderer(c))}
          {(sortedItems.contracts.length || sortedItems.offlineContracts.length) && <div style={styles.separator}>Linked Contracts</div>}
          {sortedItems.contracts.map(c => recognizedContractRenderer(c))}
          {sortedItems.offlineContracts.map(c => offlineContractRenderer(c))}
        </Menu>
      </Popover>
    </div>
  );
}

export default connect(state => ({ ...state.emails.details, user: state.login.user, mailGatesState: state.login.mailGatesState }), { getEmailDetails, sendReply, shareEmail, markAsRead, markEmailAs, changeEmailTags, sendOpenPosition })(EmailDetails);
