import React, { Component } from 'react'
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import cx from 'classnames';
import NarrowSelect from '../../NewInputs/NarrowSelect';
import RaisedButton from '../../Common/RaisedButton'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import MenuItem from 'material-ui/MenuItem';
import { CheckBox as Checkbox } from '../../Monitor/Edit/VesselEdit';
import {
  typeByPurposeList,
  typeByConstructionList,
  specialties as _specialties,
  _iceClass,
  liquidTypeByPurposeList,
  TANK_COATING_TYPES,
  HEATING_MEDIUM, IMO_APP_NAMES,
} from '../../Monitor/Edit/config'
import RegisterClass from '../../Monitor/TCOrders/RegisterClass';
import CountriesExclusions from '../../Monitor/TCOrders/CountriesExclusions';
import FittedSelect  from '../../Common/FittedSelect';
import { formatTypeByPurposePlural } from '../../../core/format';
import { GearsForm } from "../../Vessel/Common/VesselRequirements/GearsForm";
import { GrabsForm } from "../../Vessel/Common/VesselRequirements/GrabsForm";
import { PumpsForm } from "../../Vessel/Common/VesselRequirements/PumpsForm";
import s from './VesselRequirementsForm.scss'
import { MinMaxInput } from "../../Vessel/Common/VesselRequirements/MinMaxInput";
import imoClasses from "../../Monitor/Edit/imoClasses";
import { FormIGS } from "../../Vessel/Common/VesselRequirements/IgsForm";
import { mapTypeByConstruction } from '../../../core/utils';

const enabledFieldsConfig = {
  common: {
    dwtMin: true,
    dwtMax: true,
    capacityMin: true,
    capacityMax: true,
    typeByPurpose: true,
    typeBySpecialty: true,
    iceClass: true,
    loaMax: true,
    beamMax: true,
    draftMax: true,
    piClubMember: true,
    ageMin: true,
    ageMax: true,
    registerClasses: true,
    flags: true,
    ventilationType: true,
    ventilationFitted: true,
    imoFitted: true,
    imoApp: true,
    imoClass: true,
    scrubberFitted: true,
    itfFitted: true,
    lakesFitted: true,
  },
  addForDryCargo: {
    typeByConstruction: true,
    teuMin: true,
    teuMax: true,
    linerMeterMin: true,
    linerMeterMax: true,
    openHatch: true,
    boxShaped: true,
    geared: true,
    gearV2: true,
    grabbed: true,
    grabV2: true,
    referPlugFitted: true,
    referPlugQuantity: true,
    co2Fitted: true,
    a60BulkheadFitted: true,
    cementHolesFitted: true,
    logsFitted: true,
    aussieFitted: true,
    strengthenHeavy: true,
  },
  addForWetCargo: {
    pumps: true,
    wholeTank: true,
    coatingType: true,
    heatingExchanger: true,
    heatingMedium: true,
    heatingMaxLoaded: true,
    heatingMaxMaintained: true,
    newPanama: true,
    bwts: true,
    coiled: true,
    cargoTankDryer: true,
    cargoCoolingSystem: true,
    crudeOilWashing: true,
    rcttms: true,
    rctpms: true,
    bowChainStopper: true,
    chainSize: true,
    ngs: true,
    ngsCapacity: true,
    vrs: true,
    vrsNumLines: true,
    bowThruster: true,
    bowThrusterNum: true,
    bowThrusterBhp: true,
    bowThrusterKw: true,
    stemThruster: true,
    stemThrusterNum: true,
    stemThrusterBhp: true,
    stemThrusterKw: true,
    igs: true,
  },
}

const deriveEnabledFields = (typeByPurpose) => {
  const isDryCargo = !liquidTypeByPurposeList.includes(typeByPurpose);

  return {
    ...enabledFieldsConfig.common,
    ...(isDryCargo ? enabledFieldsConfig.addForDryCargo : enabledFieldsConfig.addForWetCargo)
  }
}

export default withStyles(s)(class VesselRequirementsForm extends Component {
  static defaultProps = {
    initialValues: {}
  }

  static prefix = 'vesselRequirements'

  static DWT_MIN = 650
  static DWT_MAX = 400000
  static TEU_MIN = 50
  static TEU_MAX = 30000
  static LINER_METER_MIN = 0
  static LINER_METER_MAX = 9999999
  static CAPACITY_MIN = 0
  static CAPACITY_MAX = 600000
  static AGE_MIN = 1
  static AGE_MAX = 40

  saveHandlers = []

  constructor(props) {
    super(props);

    const { initialValues } = this.props
    const { heating } = initialValues

    this.state = {
      vesselRequirements: {
        ...initialValues,
        registerClasses: initialValues.registerClasses?.map(x => ({ ...x, _id: x.registerClass && x.registerClass._id })),
        heatingExchanger: heating?.exchanger,
        heatingMedium: heating?.medium,
        heatingMaxLoaded: heating?.maxLoaded,
        heatingMaxMaintained: heating?.maxMaintained,
      },
      enabledFields: deriveEnabledFields(initialValues.typeByPurpose),
    }
  }

  getState = () => {
    const { vesselRequirements, enabledFields } = this.state

    for (const saveHandler of this.saveHandlers) {
      const { key, value } = saveHandler()
      vesselRequirements[key] = value
    }

    const values = {}

    for (const field in enabledFields) {
      values[field] = vesselRequirements[field]
    }

    for (const key of ['gearV2', 'grabV2']) {
      if (values[key]) {
        values[key].summary = values[key].summary.filter(s => s.type)
      }
    }

    if (values.pumps) {
      values.pumps = values.pumps.filter(s => s.type)
    }

    if (enabledFields.heatingExchanger) {
      values.heating = {}
      values.heating.exchanger = values.heatingExchanger
      delete values.heatingExchanger
      values.heating.medium = values.heatingMedium
      delete values.heatingMedium
      values.heating.maxLoaded = values.heatingMaxLoaded
      delete values.heatingMaxLoaded
      values.heating.maxMaintained = values.heatingMaxMaintained
      delete values.heatingMaxMaintained
    }

    if (values.igs?.systems) {
      values.igs.systems = values.igs.systems.filter(s => s.type)
    }

    return values
  }

  isTypeByPurpose = (types = []) => types.includes(this.state.vesselRequirements.typeByPurpose);

  isLinerMeterAllowed = () => {
    const allowedTypesByPurpose = ["MPP", "PASSCAR", "REEFER"];

    if (!this.state.vesselRequirements.typeByPurpose || !this.state.vesselRequirements.typeBySpecialty) return false

    return this.isTypeByPurpose(allowedTypesByPurpose) && this.state.vesselRequirements.typeBySpecialty.match(/Ro-[RL]o/)
  }

  isTeuAllowed = () => {
    const allowedTypesByPurpose = ["MPP", "CONT"];

    if (!this.state.vesselRequirements.typeByPurpose) return false;

    return this.isTypeByPurpose(allowedTypesByPurpose)
  }

  componentDidMount() {
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
  }

  setValues = (value) => {
    this.setState(s => ({
      ...s,
      vesselRequirements: {
        ...s.vesselRequirements,
        ...value,
      },
    }))
  }

  // clear dependant field when setting false or null
  setIsFitted = (keyOfBool, keysOfValue, value) => {
    const update = {
      [keyOfBool]: value,
    }

    for (const keyOfValue of (Array.isArray(keysOfValue) ? keysOfValue : [keysOfValue])) {
      update[keyOfValue] = value ? this.state.vesselRequirements[keyOfValue] : null
    }

    this.setValues(update)
  }

  // set "is fitted" to true when selecting a value
  setFittedValue(keyOfValue, keyOfBool, value) {
    this.setValues({
      [keyOfValue]: value,
      [keyOfBool]: value ? true : this.state.vesselRequirements[keyOfBool],
    })
  }

  // don't know why Promise is here, probably can be removed
  handleChange = (key, _, val) => new Promise((res, rej) => {
    this.setState(state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        [key]: val
      }
    }), res)
  })

  changeGeared = (value) => {
    this.setState( state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        geared: value,
        grabbed: value ? state.vesselRequirements.grabbed : false
      }
    }));
  }

  changeGrabbed = (value) => {
    let newState = {
      grabbed: value
    }
    if (value) {
      newState.geared = true;
    }
    this.setValues(newState)
  }

  changeTypeByPurpose = async (ev, val) => {
    const { vesselRequirements } = this.state
    const prevPurpose = vesselRequirements.typeByPurpose;
    if (val !== prevPurpose) {
      this.setState(s => ({
        ...s,
        enabledFields: deriveEnabledFields(val),
      }))
    }

    await this.handleChange('typeByPurpose', ev, val);
    if (!this.isTeuAllowed()) {
      this.handleChange("teuMin", null, "");
      this.handleChange("teuMax", null, "");
    }

    if (val !== prevPurpose) {
      this.changeTypeBySpecialty(null, "");
    }
  }

  changeTypeBySpecialty = async (ev, val) => {
    await this.handleChange('typeBySpecialty', ev, val);
    if (!this.isLinerMeterAllowed()) {
      this.handleChange("linerMeterMin", null, "");
      this.handleChange("linerMeterMax", null, "");
    }
  }

  dwtMinEventSetter = (_, val) => this.setValues({ dwtMin: val })
  dwtMaxEventSetter = (_, val) => this.setValues({ dwtMax: val })
  capacityMinEventSetter = (_, val) => this.setValues({ capacityMin: val })
  capacityMaxEventSetter = (_, val) => this.setValues({ capacityMax: val })
  iceClassEventSetter = (_, val) => this.setValues({ iceClass: val })
  loaMaxEventSetter = (_, val) => this.setValues({ loaMax: val })
  beamMaxEventSetter = (_, val) => this.setValues({ beamMax: val })
  draftMaxEventSetter = (_, val) => this.setValues({ draftMax: val })
  piClubMemberEventSetter = (_, val) => this.setValues({ piClubMember: val })
  ageMinEventSetter = (_, val) => this.setValues({ ageMin: val })
  ageMaxEventSetter = (_, val) => this.setValues({ ageMax: val })
  scrubberFittedEventSetter = (_, val) => this.setValues({ scrubberFitted: val })
  itfFittedEventSetter = (_, val) => this.setValues({ itfFitted: val })
  lakesFittedEventSetter = (_, val) => this.setValues({ lakesFitted: val })
  typeByConstructionEventSetter = (_, val) => this.setValues({ typeByConstruction: val })
  teuMinEventSetter = (_, val) => this.setValues({ teuMin: val })
  teuMaxEventSetter = (_, val) => this.setValues({ teuMax: val })
  linerMeterMinEventSetter = (_, val) => this.setValues({ linerMeterMin: val })
  linerMeterMaxEventSetter = (_, val) => this.setValues({ linerMeterMax: val })
  openHatchEventSetter = (_, val) => this.setValues({ openHatch: val })
  boxShapedEventSetter = (_, val) => this.setValues({ boxShaped: val })
  co2FittedEventSetter = (_, val) => this.setValues({ co2Fitted: val })
  a60BulkheadFittedEventSetter = (_, val) => this.setValues({ a60BulkheadFitted: val })
  cementHolesFittedEventSetter = (_, val) => this.setValues({ cementHolesFitted: val })
  logsFittedEventSetter = (_, val) => this.setValues({ logsFitted: val })
  aussieFittedEventSetter = (_, val) => this.setValues({ aussieFitted: val })
  strengthenHeavyEventSetter = (_, val) => this.setValues({ strengthenHeavy: val })
  wholeTankEventSetter = (_, val) => this.setValues({ wholeTank: val })
  coatingTypeEventSetter = (_, val) => this.setValues({ coatingType: val })
  heatingExchangerEventSetter = (_, val) => {
    this.setIsFitted('heatingExchanger', ['heatingMedium', 'heatingMaxLoaded', 'heatingMaxMaintained'], val)
  }
  heatingMediumEventSetter = (_, val) => this.setFittedValue('heatingMedium', 'heatingExchanger', val)
  heatingMaxLoadedEventSetter = (_, val) => this.setFittedValue('heatingMaxLoaded', 'heatingExchanger', val)
  heatingMaxMaintainedEventSetter = (_, val) => this.setFittedValue('heatingMaxMaintained', 'heatingExchanger', val)
  newPanamaEventSetter = (_, val) => this.setValues({ newPanama: val })
  bwtsEventSetter = (_, val) => this.setValues({ bwts: val })
  coiledEventSetter = (_, val) => this.setValues({ coiled: val })
  cargoTankDryerEventSetter = (_, val) => this.setValues({ cargoTankDryer: val })
  cargoCoolingSystemEventSetter = (_, val) => this.setValues({ cargoCoolingSystem: val })
  crudeOilWashingEventSetter = (_, val) => this.setValues({ crudeOilWashing: val })
  rcttmsEventSetter = (_, val) => this.setValues({ rcttms: val })
  rctpmsEventSetter = (_, val) => this.setValues({ rctpms: val })
  bowChainStopperEventSetter = (_, val) => this.setIsFitted('bowChainStopper', 'chainSize', val)
  chainSizeEventSetter = (_, val) => this.setFittedValue('chainSize', 'bowChainStopper', val)
  ngsEventSetter = (_, val) => this.setIsFitted('ngs', val)
  ngsCapacityEventSetter = (_, val) => this.setFittedValue('ngsCapacity', 'ngs', val)
  vrsEventSetter = (_, val) => this.setIsFitted('vrs', val)
  vrsNumLinesEventSetter = (_, val) => this.setFittedValue('vrsNumLines', 'vrs', val)
  bowThrusterEventSetter = (_, val) => {
    this.setIsFitted('bowThruster', ['bowThrusterNum', 'bowThrusterBhp', 'bowThrusterKw'], val)
  }
  bowThrusterNumEventSetter = (_, val) => this.setFittedValue('bowThrusterNum', 'bowThruster', val)
  bowThrusterBhpEventSetter = (_, val) => this.setFittedValue('bowThrusterBhp', 'bowThruster', val)
  bowThrusterKwEventSetter = (_, val) => this.setFittedValue('bowThrusterKw', 'bowThruster', val)
  stemThrusterEventSetter = (_, val) => {
    this.setIsFitted('stemThruster', ['stemThrusterNum', 'stemThrusterBhp', 'stemThrusterKw'], val)
  }
  stemThrusterNumEventSetter = (_, val) => this.setFittedValue('stemThrusterNum', 'stemThruster', val)
  stemThrusterBhpEventSetter = (_, val) => this.setFittedValue('stemThrusterBhp', 'stemThruster', val)
  stemThrusterKwEventSetter = (_, val) => this.setFittedValue('stemThrusterKw', 'stemThruster', val)

  render() {
    const { kind } = this.props;
    const values = this.state.vesselRequirements;

    const isForCargo = kind === 'cargo';

    const { prefix, DWT_MIN, DWT_MAX, CAPACITY_MAX, CAPACITY_MIN, AGE_MIN, AGE_MAX, TEU_MIN, TEU_MAX, LINER_METER_MAX, LINER_METER_MIN } = this.constructor;

    const blockTitleClass = cx(s.m_0, s.text_xs, isForCargo ? s.text_bg_blue : s.text_green_dark)

    const isDryCargo = !liquidTypeByPurposeList.includes(values.typeByPurpose);

    const { enabledFields } = this.state

    return (
      <div className={s.py_8px}>
        <div className={cx(s.grid, s.grid_cols_4, s.gap_x_16px, s.items_center)}>
          {enabledFields.dwtMax && (
            <MinMaxInput
              val={values.dwtMax}
              min={DWT_MIN}
              max={DWT_MAX}
              extraDef={{
                isInt: 'only integer',
              }}
            >
              <NarrowFormsyText
                floatingLabelText="DWT min, mt"
                name={`${prefix}[dwtMin]`}
                fullWidth
                value={values.dwtMin}
                onBlur={this.dwtMinEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.dwtMin && (
            <MinMaxInput
              val={values.dwtMin}
              min={DWT_MIN}
              max={DWT_MAX}
              extraDef={{
                isInt: 'only integer',
              }}
              type="max"
            >
              <NarrowFormsyText
                floatingLabelText="DWT max, mt"
                name={`${prefix}[dwtMax]`}
                fullWidth
                value={values.dwtMax}
                onBlur={this.dwtMaxEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.capacityMin && (
            <MinMaxInput
              val={values.capacityMax}
              min={CAPACITY_MIN}
              max={CAPACITY_MAX}
            >
              <NarrowFormsyText
                floatingLabelText="Capacity min, m3"
                name={`${prefix}[capacityMin]`}
                fullWidth
                value={values.capacityMin}
                onBlur={this.capacityMinEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.capacityMax && (
            <MinMaxInput
              val={values.capacityMin}
              min={CAPACITY_MIN}
              max={CAPACITY_MAX}
              type="max"
            >
              <NarrowFormsyText
                floatingLabelText="Capacity max, m3"
                name={`${prefix}[capacityMax]`}
                fullWidth
                value={values.capacityMax}
                onBlur={this.capacityMaxEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.typeByPurpose && (
            <NarrowSelect
              floatingLabelText="Type By Purpose"
              name={`${prefix}[typeByPurpose]`}
              fullWidth
              autoWidth
              value={values.typeByPurpose}
              onChange={this.changeTypeByPurpose}
            >
              {
                typeByPurposeList.map((item, i) => (
                  <MenuItem key={i} value={item} primaryText={formatTypeByPurposePlural(item, 1)}/>
                ))
              }
            </NarrowSelect>
          )}
          {enabledFields.typeBySpecialty && (
            <NarrowSelect
              floatingLabelText="Type By Specialty"
              name={`${prefix}[typeBySpecialty]`}
              disabled={!values.typeByPurpose}
              autoWidth
              fullWidth
              value={values.typeBySpecialty}
              onChange={this.changeTypeBySpecialty}
              dropDownMenuProps={{
                popoverProps: {
                  style: {
                    background: 'red'
                  }
                }
              }}
            >
              {
                _specialties[values.typeByPurpose ? values.typeByPurpose.toLowerCase() : 'bulk'].map((item, i) => (
                  <MenuItem key={i} value={item} primaryText={item}/>
                ))
              }
            </NarrowSelect>
          )}
          {enabledFields.typeByConstruction && (
            <NarrowSelect
              floatingLabelText="Type By Construction"
              name={`${prefix}[typeByConstruction]`}
              fullWidth
              value={values.typeByConstruction}
              onChange={this.typeByConstructionEventSetter}
            >
              {
                typeByConstructionList.map((item, i) => (
                  <MenuItem key={i} value={item} primaryText={mapTypeByConstruction(item)}/>
                ))
              }
            </NarrowSelect>
          )}
          {enabledFields.iceClass && (
            <NarrowSelect
              floatingLabelText="Ice Class"
              name={`${prefix}[iceClass]`}
              fullWidth
              value={values.iceClass}
              onChange={this.iceClassEventSetter}
            >
              {_iceClass.map(item => <MenuItem value={item} primaryText={item}></MenuItem>)}
            </NarrowSelect>
          )}
          {enabledFields.teuMax && (
            <MinMaxInput
              val={values.teuMax}
              min={TEU_MIN}
              max={TEU_MAX}
            >
              <NarrowFormsyText
                floatingLabelText={`TEU min`}
                name={`${prefix}[teuMin]`}
                fullWidth
                value={values.teuMin}
                disabled={!this.isTeuAllowed()}
                onBlur={this.teuMinEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.teuMin && (
            <MinMaxInput
              val={values.teuMin}
              min={TEU_MIN}
              max={TEU_MAX}
              type="max"
            >
              <NarrowFormsyText
                floatingLabelText={`TEU max`}
                name={`${prefix}[teuMax]`}
                fullWidth
                disabled={!this.isTeuAllowed()}
                value={values.teuMax}
                onBlur={this.teuMaxEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.linerMeterMin && (
            <MinMaxInput
              val={values.linerMeterMax}
              min={LINER_METER_MIN}
              max={LINER_METER_MAX}
            >
              <NarrowFormsyText
                floatingLabelText="Lane meter min"
                name={`${prefix}[linerMeterMin]`}
                fullWidth
                value={values.linerMeterMin}
                disabled={!this.isLinerMeterAllowed()}
                onBlur={this.linerMeterMinEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.linerMeterMax && (
            <MinMaxInput
              val={values.linerMeterMin}
              min={LINER_METER_MIN}
              max={LINER_METER_MAX}
              type="max"
            >
              <NarrowFormsyText
                floatingLabelText="Lane meter max"
                name={`${prefix}[linerMeterMax]`}
                fullWidth
                value={values.linerMeterMax}
                disabled={!this.isLinerMeterAllowed()}
                onBlur={this.linerMeterMaxEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.loaMax && (
            <NarrowFormsyText
              floatingLabelText="LOA max, m"
              validations="isNumeric"
              validationErrors={{
                isNumeric: 'only numbers',
              }}
              value={values.loaMax}
              name={`${prefix}[loaMax]`}
              onBlur={this.loaMaxEventSetter}
              fullWidth
            />
          )}
          {enabledFields.beamMax && (
            <NarrowFormsyText
              floatingLabelText="Beam max, m"
              validations="isNumeric"
              validationErrors={{
                isNumeric: 'only numbers',
              }}
              value={values.beamMax}
              name={`${prefix}[beamMax]`}
              onBlur={this.beamMaxEventSetter}
              fullWidth
            />
          )}
          {enabledFields.draftMax && (
            <NarrowFormsyText
              floatingLabelText="Draft max, m"
              validations="isNumeric"
              validationErrors={{
                isNumeric: 'only numbers',
              }}
              value={values.draftMax}
              name={`${prefix}[draftMax]`}
              onBlur={this.draftMaxEventSetter}
              fullWidth
            />
          )}
          {isDryCargo && (
            <Checkbox
              className={cx(s.checkbox)}
              checked={values.piClubMember}
              label="P&I Club"
              onChange={this.piClubMemberEventSetter}
              name={`${prefix}[piClubMember]`}
            />
          )}
          {enabledFields.ageMin && (
            <MinMaxInput
              val={values.ageMax}
              min={AGE_MIN}
              max={AGE_MAX}
              extraDef={{
                isInt: 'only integer',
              }}
            >
              <NarrowFormsyText
                floatingLabelText="Age min, years"
                name={`${prefix}[ageMin]`}
                fullWidth
                value={values.ageMin}
                onBlur={this.ageMinEventSetter}
              />
            </MinMaxInput>
          )}
          {enabledFields.ageMax && (
            <MinMaxInput
              val={values.ageMin}
              min={AGE_MIN}
              max={AGE_MAX}
              extraDef={{
                isInt: 'only integer',
              }}
              type="max"
            >
              <NarrowFormsyText
                floatingLabelText="Age max, years"
                name={`${prefix}[ageMax]`}
                fullWidth
                value={values.ageMax}
                onBlur={this.ageMaxEventSetter}
              />
            </MinMaxInput>
          )}
          {!isDryCargo && (
            <div className={cx(s.form_row_min_h, s.flex, s.items_center)}>
              <Checkbox
                className={cx(s.checkbox)}
                checked={values.piClubMember}
                label="P&I Club"
                onChange={this.piClubMemberEventSetter}
                name={`${prefix}[piClubMember]`}
              />
            </div>
          )}
          {enabledFields.openHatch && (
            <FittedSelect
              name={`${prefix}[openHatch]`}
              onChange={this.openHatchEventSetter}
              value={values.openHatch}
              floatingLabelText={'Open Hatch'}
            />
          )}
          {enabledFields.boxShaped && (
            <FittedSelect
              name={`${prefix}[boxShaped]`}
              onChange={this.boxShapedEventSetter}
              value={values.boxShaped}
              floatingLabelText={'Box Shaped'}
            />
          )}
        </div>
        {enabledFields.registerClasses && (
          <RegisterClass
            useUuidForAny={false}
            registerClasses={values.registerClasses}
            handleChange={this.handleChange}
            captureSaveHandler={(saveHandler) => this.saveHandlers.push(saveHandler)}
            chipStyle={{ margin: '0 8px 16px 0px' }}
          />
        )}
        {enabledFields.flags && (
          <CountriesExclusions
            prefix={prefix}
            handleChange={this.handleChange}
            captureSaveHandler={(saveHandler) => this.saveHandlers.push(saveHandler)}
            flags={values.flags}
            chipStyle={{ margin: '0 8px 16px 0px' }}
          />
        )}
        {enabledFields.geared && (
          <GearsForm
            key='geared'
            geared={values.geared}
            prefix={prefix}
            gearV2={values.gearV2}
            blockTitleClass={blockTitleClass}
            onChangeSelect={this.changeGeared}
            onChange={this.setValues}
          />
        )}
        {enabledFields.grabbed && (
          <GrabsForm
            key='grabbed'
            grabbed={values.grabbed}
            geared={values.geared}
            prefix={prefix}
            grabV2={values.grabV2}
            blockTitleClass={blockTitleClass}
            onChangeSelect={this.changeGrabbed}
            onChange={this.setValues}
          />
        )}
        {enabledFields.pumps && (
          <PumpsForm
            key='pumps'
            prefix={prefix}
            pumps={values.pumps}
            blockTitleClass={blockTitleClass}
            onChange={this.setValues}
          />
        )}
        {enabledFields.coatingType && (
          <div>
            <div className={cx(s.form_row_min_h, s.flex, s.items_center)}>
              <h6 className={blockTitleClass}>TANK COATING</h6>
            </div>
            <div className={cx(s.grid, s.grid_cols_4, s.gap_x_16px, s.items_center)}>
              <Checkbox
                className={cx(s.checkbox)}
                checked={values.wholeTank}
                label="Whole Tank"
                onChange={this.wholeTankEventSetter}
                name={`${prefix}[wholeTank]`}
              />
              <NarrowSelect
                floatingLabelText="Type"
                name={`${prefix}[coatingType]`}
                fullWidth
                autoWidth
                value={values.coatingType}
                onChange={this.coatingTypeEventSetter}
              >
                {TANK_COATING_TYPES.map(item =>
                  <MenuItem value={item} primaryText={item} />
                )}
              </NarrowSelect>
            </div>
          </div>
        )}
        {enabledFields.heatingExchanger && (
          <div>
            <div style={{ height: '44px', display: 'flex', alignItems: 'center', marginTop: 8 }}>
              <h6 className={blockTitleClass}>HEATING</h6>
            </div>
            <div className={cx(s.grid, s.grid_cols_4, s.gap_x_16px, s.items_center)}>
              <FittedSelect
                name={`${prefix}[heatingExchanger]`}
                onChange={this.heatingExchangerEventSetter}
                value={values.heatingExchanger}
                floatingLabelText="Heat Exchanger"
              />
              <NarrowSelect
                floatingLabelText="Heating medium"
                name={`${prefix}[heatingMedium]`}
                fullWidth
                autoWidth
                value={values.heatingMedium}
                onChange={this.heatingMediumEventSetter}
              >
                {HEATING_MEDIUM.map(item =>
                  <MenuItem value={item} primaryText={item}/>
                )}
              </NarrowSelect>
              <NarrowFormsyText
                floatingLabelText="Max loaded, °C"
                validations="isNumeric"
                validationErrors={{
                  isNumeric: 'only numbers',
                }}
                name={`${prefix}[heatingMaxLoaded]`}
                value={values.heatingMaxLoaded}
                onBlur={this.heatingMaxLoadedEventSetter}
                fullWidth
              />
              <NarrowFormsyText
                floatingLabelText="Max maintained, °C"
                validations="isNumeric"
                validationErrors={{
                  isNumeric: 'only numbers',
                }}
                name={`${prefix}[heatingMaxMaintained]`}
                value={values.heatingMaxMaintained}
                onBlur={this.heatingMaxMaintainedEventSetter}
                fullWidth
              />
            </div>
          </div>
        )}

        <h6 className={cx(s.form_row_min_h, s.flex, s.items_center, s.mt_8px, blockTitleClass)}>
          TECHNICAL EQUIPMENT / CERTIFICATE
        </h6>

        <div className={cx(s.grid, s.grid_cols_4, s.gap_x_16px, s.items_center)}>
          {enabledFields.ventilationFitted && (
            <FittedSelect
              floatingLabelText="Ventilation"
              value={values.ventilationFitted}
              onChange={(_, val) =>
                this.setIsFitted('ventilationFitted', 'ventilationType', val)
              }
              name={`${prefix}[ventilationFitted]`}
            />
          )}
          {enabledFields.ventilationType && (
            <NarrowSelect
              floatingLabelText="Ventilation type"
              name={`${prefix}[ventilation]`}
              value={values.ventilationType}
              fullWidth
              onChange={(_, val) =>
                this.setFittedValue('ventilationType', 'ventilationFitted', val)
              }
            >
              <MenuItem value="Electrical" primaryText="Electrical"/>
              <MenuItem value="Natural" primaryText="Natural"/>
              <MenuItem value={null} primaryText=""/>
            </NarrowSelect>
          )}
          {enabledFields.referPlugFitted && (
            <FittedSelect
              name={`${prefix}[referPlugFitted]`}
              floatingLabelText={"Reefer plug"}
              onChange={(_, val) =>
                this.setIsFitted('referPlugFitted', 'referPlugQuantity', val)
              }
              value={values.referPlugFitted}
            />
          )}
          {enabledFields.referPlugQuantity && (
            <NarrowFormsyText
              floatingLabelText="Refer plugs quantity"
              name={`${prefix}[referPlugQuantity]`}
              validations="isNumeric,isInt,min:0,max:1000"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: '0 - 1000',
                max: '0 - 1000',
              }}
              value={values.referPlugQuantity}
              fullWidth
              onBlur={(_, val) =>
                this.setFittedValue('referPlugQuantity', 'referPlugFitted', val)
              }
            />
          )}
          {enabledFields.imoFitted && (
            <FittedSelect
              value={values.imoFitted}
              onChange={(_, val) =>
                this.setIsFitted('referPlugFitted', ['imoApp', 'imoClass'], val)
              }
              name={`${prefix}[imoFitted]`}
              floatingLabelText="Imo fitted"
            />
          )}
          {enabledFields.imoApp && (
            <NarrowSelect
              floatingLabelText="Imo app"
              name={`${prefix}[imoApp]`}
              fullWidth
              value={values.imoApp}
              onChange={(_, val) =>
                this.setFittedValue('imoApp', 'imoFitted', val)
              }
            >
              {IMO_APP_NAMES.map(option =>
                <MenuItem value={option} primaryText={option} />
              )}
              <MenuItem value={""} primaryText=""/>
            </NarrowSelect>
          )}
          {enabledFields.imoClass && (
            <NarrowSelect
              floatingLabelText="Imo class"
              name={`${prefix}[imoClass]`}
              fullWidth
              value={values.imoClass}
              onChange={(_, val) =>
                this.setFittedValue('imoClass', 'imoFitted', val)
              }
            >
              {imoClasses.map(option =>
                <MenuItem key={option} value={option} primaryText={option} />
              )}
              <MenuItem value={null} primaryText=""/>
            </NarrowSelect>
          )}
          {enabledFields.scrubberFitted && (
            <FittedSelect
              name={`${prefix}[scrubberFitted]`}
              floatingLabelText="Scrubber"
              value={values.scrubberFitted}
              onChange={this.scrubberFittedEventSetter}
            />
          )}
          {enabledFields.co2Fitted && (
            <FittedSelect
              name={`${prefix}[co2Fitted]`}
              floatingLabelText="CO2"
              value={values.co2Fitted}
              onChange={this.co2FittedEventSetter}
            />
          )}
          {enabledFields.a60BulkheadFitted && (
            <FittedSelect
              name={`${prefix}[a60BulkheadFitted]`}
              value={values.a60BulkheadFitted}
              floatingLabelText="A60 bulkhead"
              onChange={this.a60BulkheadFittedEventSetter}
            />
          )}
          {enabledFields.itfFitted && (
            <FittedSelect
              name={`${prefix}[itfFitted]`}
              floatingLabelText="Itf"
              value={values.itfFitted}
              onChange={this.itfFittedEventSetter}
            />
          )}
          {enabledFields.cementHolesFitted && (
            <FittedSelect
              name={`${prefix}[cementHolesFitted]`}
              floatingLabelText="Cement holes"
              value={values.cementHolesFitted}
              onChange={this.cementHolesFittedEventSetter}
            />
          )}
          {enabledFields.lakesFitted && (
            <FittedSelect
              name={`${prefix}[lakesFitted]`}
              floatingLabelText="Lakes"
              value={values.lakesFitted}
              onChange={this.lakesFittedEventSetter}
            />
          )}
          {enabledFields.logsFitted && (
            <FittedSelect
              name={`${prefix}[logsFitted]`}
              floatingLabelText="Logs"
              value={values.logsFitted}
              onChange={this.logsFittedEventSetter}
            />
          )}
          {enabledFields.aussieFitted && (
            <FittedSelect
              name={`${prefix}[aussieFitted]`}
              floatingLabelText="Aussie"
              value={values.aussieFitted}
              onChange={this.aussieFittedEventSetter}
            />
          )}
          {enabledFields.strengthenHeavy && (
            <FittedSelect
              name={`${prefix}[strengthenHeavy]`}
              value={values.strengthenHeavy}
              floatingLabelText="Heavy cargoes"
              onChange={this.strengthenHeavyEventSetter}
            />
          )}
          {enabledFields.newPanama && (
            <FittedSelect
              name={`${prefix}[newPanama]`}
              value={values.newPanama}
              floatingLabelText="New Panama"
              onChange={this.newPanamaEventSetter}
            />
          )}
          {enabledFields.bwts && (
            <FittedSelect
              name={`${prefix}[bwts]`}
              value={values.bwts}
              floatingLabelText="BWTS"
              hint="Ballast water treatment system"
              onChange={this.bwtsEventSetter}
            />
          )}
          {enabledFields.coiled && (
            <FittedSelect
              name={`${prefix}[coiled]`}
              value={values.coiled}
              floatingLabelText="Coiled"
              onChange={this.coiledEventSetter}
            />
          )}
          {enabledFields.cargoTankDryer && (
            <FittedSelect
              name={`${prefix}[cargoTankDryer]`}
              value={values.cargoTankDryer}
              floatingLabelText="Cargo tank dryer"
              onChange={this.cargoTankDryerEventSetter}
            />
          )}
          {enabledFields.cargoCoolingSystem && (
            <FittedSelect
              name={`${prefix}[cargoCoolingSystem]`}
              value={values.cargoCoolingSystem}
              floatingLabelText="Cargo cooling system"
              onChange={this.cargoCoolingSystemEventSetter}
            />
          )}
          {enabledFields.crudeOilWashing && (
            <FittedSelect
              name={`${prefix}[crudeOilWashing]`}
              value={values.crudeOilWashing}
              floatingLabelText="Crude oil washing"
              onChange={this.crudeOilWashingEventSetter}
            />
          )}
          {enabledFields.rcttms && (
            <FittedSelect
              name={`${prefix}[rcttms]`}
              value={values.rcttms}
              floatingLabelText="RCTTMS"
              hint="Remote cargo tank temperature monitoring system"
              onChange={this.rcttmsEventSetter}
            />
          )}
          {enabledFields.rctpms && (
            <FittedSelect
              name={`${prefix}[rctpms]`}
              value={values.rctpms}
              floatingLabelText="RCTPMS"
              hint="Remote cargo tank pressure monitoring system"
              onChange={this.rctpmsEventSetter}
            />
          )}
          {enabledFields.bowChainStopper && (
            <FittedSelect
              name={`${prefix}[bowChainStopper]`}
              value={values.bowChainStopper}
              floatingLabelText="Bow chain stopper"
              onChange={this.bowChainStopperEventSetter}
            />
          )}
          {enabledFields.chainSize && (
            <NarrowFormsyText
              name={`${prefix}[chainSize]`}
              floatingLabelText="Chain size, mm"
              validations={'isNumeric,isInt,min:0'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.chainSizeEventSetter}
              value={values.chainSize || ''}
              fullWidth
            />
          )}
          {!isDryCargo && <div />}
          {!isDryCargo && <div />}
          {enabledFields.ngs && (
            <FittedSelect
              name={`${prefix}[ngs]`}
              value={values.ngs}
              floatingLabelText="NGS"
              onChange={this.ngsEventSetter}
            />
          )}
          {enabledFields.ngsCapacity && (
            <NarrowFormsyText
              name={`${prefix}[ngsCapacity]`}
              floatingLabelText="Capacity, cbm/hr (95%)"
              validations={'isNumeric,isInt,min:0'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.ngsCapacityEventSetter}
              value={values.ngsCapacity || ''}
              fullWidth
            />
          )}
          {enabledFields.vrs && (
            <FittedSelect
              name={`${prefix}[vrs]`}
              value={values.vrs}
              floatingLabelText="VRS"
              onChange={this.vrsEventSetter}
            />
          )}
          {enabledFields.vrsNumLines && (
            <NarrowFormsyText
              name={`${prefix}[vrsNumLines]`}
              floatingLabelText="Num of independent lines"
              validations={'isNumeric,isInt,min:0'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.vrsNumLinesEventSetter}
              value={values.vrsNumLines || ''}
              fullWidth
            />
          )}
          {enabledFields.bowThruster && (
            <FittedSelect
              name={`${prefix}[bowThruster]`}
              value={values.bowThruster}
              floatingLabelText="Bow thruster"
              onChange={this.bowThrusterEventSetter}
            />
          )}
          {enabledFields.bowThrusterNum && (
            <NarrowFormsyText
              name={`${prefix}[bowThrusterNum]`}
              floatingLabelText="Num, pc"
              validations="isNumeric,isInt,min:0"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.bowThrusterNumEventSetter}
              value={values.bowThrusterNum || ''}
              fullWidth
            />
          )}
          {enabledFields.bowThrusterBhp && (
            <NarrowFormsyText
              name={`${prefix}[bowThrusterBhp]`}
              floatingLabelText="BHP"
              validations="isNumeric,isInt,min:0"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.bowThrusterBhpEventSetter}
              value={values.bowThrusterBhp || ''}
              fullWidth
            />
          )}
          {enabledFields.bowThrusterKw && (
            <NarrowFormsyText
              name={`${prefix}[bowThrusterKw]`}
              floatingLabelText="kW"
              validations="isNumeric,isInt,min:0"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.bowThrusterKwEventSetter}
              value={values.bowThrusterKw || ''}
              fullWidth
            />
          )}
          {enabledFields.stemThruster && (
            <FittedSelect
              name={`${prefix}[stemThruster]`}
              value={values.stemThruster}
              floatingLabelText="Stem thruster"
              onChange={this.stemThrusterEventSetter}
            />
          )}
          {enabledFields.stemThrusterNum && (
            <NarrowFormsyText
              name={`${prefix}[stemThrusterNum]`}
              floatingLabelText="Num, pc"
              validations="isNumeric,isInt,min:0"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.stemThrusterNumEventSetter}
              value={values.stemThrusterNum || ''}
              fullWidth
            />
          )}
          {enabledFields.stemThrusterBhp && (
            <NarrowFormsyText
              name={`${prefix}[stemThrusterBhp]`}
              floatingLabelText="BHP"
              validations="isNumeric,isInt,min:0"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.stemThrusterBhpEventSetter}
              value={values.stemThrusterBhp || ''}
              fullWidth
            />
          )}
          {enabledFields.stemThrusterKw && (
            <NarrowFormsyText
              name={`${prefix}[stemThrusterKw]`}
              floatingLabelText="kW"
              validations="isNumeric,isInt,min:0"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onBlur={this.stemThrusterKwEventSetter}
              value={values.stemThrusterKw || ''}
              fullWidth
            />
          )}
        </div>

        {enabledFields.igs && (
          <FormIGS
            prefix={prefix}
            value={values.igs}
            onChange={this.setValues}
          />
        )}

        <span>
          <RaisedButton
            label="NEXT"
            disableTouchRipple
            disableFocusRipple
            primary
            type="submit"
            style={{ margin: '40px 0 6px 0' }}
          />
        </span>
      </div>
    )
  }
})
