import React, { Component } from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from './VesselRequirementsPreview.scss'
import cx from "classnames";
import ArrowUp from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import ArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import { formatTypeByPurposePlural, number } from "../../../core/format";
import { gearLabels } from '../../Monitor/Edit/config';

export function hasVesselRequirements(values) {
  if (values) {
    for (const key in values) {
      if (key === '_id') continue

      const value = values[key]
      if (!has(value)) continue

      if (typeof value === 'object') {
        // summary is for gearV2 and grabV2, systems is for IGS, value is for pumps
        const maybeArr = value.summary || value.systems || value
        if (Array.isArray(maybeArr) && !maybeArr.length) {
          continue
        }
      }

      return true
    }
  }

  return false
}

export default withStyles(s)(class VesselRequirementsPreview extends Component {
  state = {
    show: false
  }

  onToggle = () => {
    this.setState(s => ({ ...s, show: !s.show }))
  }

  render() {
    const { show } = this.state
    const { vesselRequirements: values, buttonClass, contentClass } = this.props

    const registerClasses = values.registerClasses?.filter(x => !x.excluded)
    const excludedClasses = values.registerClasses?.filter(x => x.excluded)

    const flags = values.flags?.filter(x => !x.excluded)
    const excludedFlags = values.flags?.filter(x => x.excluded)

    return (
      <div className={cx(s.pl_6px, s.pr_2px)}>
        <button
          type='button'
          className={cx(s.flex, s.items_center, s.border_b, s.border_light_gray1, s.font_medium, s.text_12px, s.toggle_button, buttonClass)}
          onClick={this.onToggle}
        >
          {show
            ? <ArrowUp style={{ width: 24, height: 24 }}/>
            : <ArrowDown style={{ width: 24, height: 24 }}/>
          }
          {formatSummary(values)}
        </button>
        {show && (
          <div className={cx(s.pt_8px, s.line_h_24px, s.text_12px, contentClass)}>
            {minMax('DWT (min/max)', values.dwtMin, values.dwtMax, 'mt')}
            {minMax('Capacity (min/max)', values.capacityMin, values.capacityMax, 'm3')}
            {has(values.typeByPurpose) && (
              line('Type by Purpose', formatTypeByPurposePlural(values.typeByPurpose, 1))
            )}
            {line('Type by Specialty', values.typeBySpecialty)}
            {line('Type by Construction', values.typeByConstruction)}
            {line('Ice class', values.iceClass)}
            {minMax('Teu (min/max)', values.teuMin, values.teuMax)}
            {minMax('Lane meter (min/max)', values.linerMeterMin, values.linerMeterMax)}
            {minMax('Age (min/max)', values.ageMin, values.ageMax)}
            {has(values.openHatch) && line('Open Hatch', values.openHatch ? 'fitted' : 'not fitted')}
            {has(values.boxShaped) && line('Box shaped', values.boxShaped ? 'fitted' : 'not fitted')}

            {hasSome(values.loaMax, values.beamMax, values.draftMax) && (
              <div>
                <span className={s.font_medium}>Dimensions:</span>
                {' ' + [
                  has(values.loaMax) && `LOA max: ${number(values.loaMax, '', 'm')}`,
                  has(values.beamMax) && `Beam max: ${number(values.beamMax, '', 'm')}`,
                  has(values.draftMax) && `Draft max: ${number(values.draftMax, '', 'm')}`,
                ].filter(Boolean).join('; ')}
              </div>
            )}

            {(registerClasses?.length || 0) > 0 && line(
              'Register Class',
                registerClasses.map(x => `${x.registerClass ? x.registerClass.name : 'Any'}${x.iacsRegister ? ' (IACS)' : ''}`).join(', ')
            )}

            {(excludedClasses?.length || 0) > 0 && line(
              'Excluded Register Class',
                excludedClasses.map(x => `${x.registerClass ? x.registerClass.name : 'Any'}${x.iacsRegister ? ' (IACS)' : ''}`).join(', ')
            )}

            {(flags?.length || 0) > 0 && line(
              'Flag',
                flags.map(x => x.flag.name).join(', ')
            )}

            {(excludedFlags?.length || 0) > 0 && line(
              'Excluded Flag',
                excludedFlags.map(x => x.flag.name).join(', ')
            )}

            {values.gearV2 && multiline(
              'Gear/Outreach',
              (values.gearV2?.summary?.length || 0) > 0
                ? values.gearV2.summary.map(x => (
                  [
                    `${gearLabels[x.type]}${has(x.quantity) ? ` ${x.quantity}x` : ''}`,
                    minMaxText('capacity', x.capacity, x.capacityMax, 'mt'),
                    has(x.outreach) && `outreach ${number(x.outreach, '', 'm')}`,
                  ].filter(Boolean).join(', ')
                ))
                : has(values.geared) ? ['Geared'] : ['Gearless']
            )}

            {values.grabV2 && multiline(
              'Grabs',
              (values.grabV2?.summary?.length || 0) > 0
                ? values.grabV2.summary.map(x => (
                  [
                    `${x.type}${has(x.quantity) ? ` ${x.quantity}x` : ''}`,
                    minMaxText('volume', x.volume, x.volumeMax, 'cbm'),
                  ].filter(Boolean).join(', ')
                ))
                : has(values.grabbed) ? ['fitted'] : ['not fitted']
            )}

            {(values.pumps?.length || 0) > 0 && multiline(
            'Cargo Pumps',
              values.pumps.map(x => (
                [
                  `${x.type}${has(x.quantity) ? ` ${x.quantity}x` : ''}`,
                  has(x.capacity) && `capacity ${number(x.capacity, '', 'm3/hour')}`,
                ].filter(Boolean).join(', ')
              ))
            )}

            {has(values.coatingType) && line(
              'Tank Coating',
              `${values.coatingType}${values.wholeTank ? ', whole tank' : ''}`
            )}

            {has(values.heatingExchanger) && line(
              'Heat Exchanger',
              values.heatingExchanger
                ? hasSome(values.heatingMedium, values.heatingMaxLoaded, values.heatingMaxMaintained)
                  ? [
                      values.heatingMedium,
                      has(values.heatingMaxLoaded) && `max loaded ${number(values.heatingMaxLoaded, '', '°C')}`,
                      has(values.heatingMaxMaintained) && `max maintained ${number(values.heatingMaxMaintained, '', '°C')}`,
                    ].filter(Boolean).join(', ')
                  : 'fitted'
                : 'not fitted'
            )}

            {multiline(
              'Technical Equipment / Certificate',
              [
                values.piClubMember && 'P&I club',
                has(values.ventilationFitted) && (
                  values.ventilationFitted
                    ? values.ventilationType
                      ? `Ventilation: ${values.ventilationType}`
                      : 'Ventilation fitted'
                    : 'Ventilation not fitted'
                ),
                has(values.referPlugFitted) && (
                  values.referPlugFitted
                    ? `Reefer plugs fitted${
                        has(values.referPlugQuantity)
                          ? `, quantity ${values.referPlugQuantity}`
                          : ''
                      }`
                    : 'Reefer plugs not fitted'
                ),
                has(values.imoFitted) && !has(values.imoApp) && !has(values.imoClass) && (
                  values.imoFitted
                    ? 'Imo set'
                    : 'Imo not set'
                ),
                has(values.imoApp) && `Imo appendix: ${values.imoApp}`,
                has(values.imoClass) && `Imo class: ${values.imoClass}`,
                fittedText('Scrubber', values.scrubberFitted),
                fittedText('CO2', values.co2Fitted),
                fittedText('A60 bulkhead', values.a60BulkheadFitted),
                fittedText('Itf', values.itfFitted),
                fittedText('Cement holes', values.cementHolesFitted),
                fittedText('Lakes', values.lakesFitted),
                fittedText('Logs', values.logsFitted),
                fittedText('Aussie', values.aussieFitted),
                fittedText('Heavy cargoes', values.strengthenHeavy),
                fittedText('New Panama', values.newPanama),
                fittedText('BWTS', values.bwts),
                fittedText('Coiled', values.coiled),
                fittedText('Cargo tank dryer', values.cargoTankDryer),
                fittedText('Cargo cooling system', values.cargoCoolingSystem),
                fittedText('Crude oil washing', values.crudeOilWashing),
                fittedText('RCTTMS', values.rcttms),
                fittedText('RCTPMS', values.rctpms),
                hasSome(values.bowChainStopper, values.chainSize) && (
                  `Bow chain stopper ${
                    values.bowChainStopper ? 'fitted' : 'not fitted'
                  }${
                    has(values.chainSize) ? ` ${number(values.chainSize, '', 'mm')}` : ''
                  }`
                ),
                has(values.ngs) && (
                  `NGS ${
                    values.ngs
                      ? `fitted${
                          has(values.ngsCapacity)
                            ? `: ${number(values.ngsCapacity, '', 'cbm/hr (95%)')}`
                            : ''
                        }`
                      : 'not fitted'
                  }`
                ),
                has(values.vrs) && (
                  `VRS ${
                    values.vrs
                      ? `fitted${
                        has(values.vrsNumLines)
                          ? `: Num of independent lines: ${number(values.vrsNumLines)}`
                          : ''
                      }`
                      : 'not fitted'
                  }`
                ),
                has(values.bowThruster) && (
                  `${
                    fittedText('Bow thruster', values.bowThruster)
                  }${
                    hasSome(values.bowThrusterNum, values.bowThrusterBhp, values.bowThrusterKw) ? `: ${
                      [
                        has(values.bowThrusterNum) && number(values.bowThrusterNum, '', 'pc'),
                        values.bowThrusterBhp && number(values.bowThrusterBhp, '', 'BHP'),
                        values.bowThrusterKw && number(values.bowThrusterKw, '', 'kW'),
                      ].filter(Boolean).join(', ')
                    }` : ''}`
                ),
                has(values.stemThruster) && (
                  `${
                    fittedText('Stem thruster', values.stemThruster)
                  }${
                    hasSome(values.stemThrusterNum, values.stemThrusterBhp, values.stemThrusterKw) ? `: ${
                      [
                        has(values.stemThrusterNum) && number(values.stemThrusterNum, '', 'pc'),
                        values.stemThrusterBhp && number(values.stemThrusterBhp, '', 'BHP'),
                        values.stemThrusterKw && number(values.stemThrusterKw, '', 'kW'),
                      ].filter(Boolean).join(', ')
                    }` : ''}`
                ),
              ]
            )}

            {has(values.igs?.fitted) && (
              <div>
                <span className={s.font_medium}>IGS {values.igs.fitted ? 'fitted' : 'not fitted'}</span>
                {values.igs.fitted && (
                  <ul style={{ margin: 0, paddingLeft: '22px' }}>
                    {values.igs.systems.map((x, i) => (
                      <li key={i}>
                        {x.type}
                        {has(x.capacity)
                          ? `, capacity ${
                            number(x.capacity, '', ' cbm/hr')
                          }${
                            x.percentage ? ` at ${x.percentage}%` : ''
                          }`
                          : ''
                        }
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
})

function has(value) {
  return value !== null && value !== undefined && value !== ''
}

function hasSome(...values) {
  return values.some(has)
}

function hasEvery(...values) {
  return values.every(has)
}

function formatSummary(values) {
  let s = ''

  if (values.typeByPurpose) {
    s = formatTypeByPurposePlural(values.typeByPurpose, 1)
  }

  if (values.typeBySpecialty) {
    s = s ? `${s} (${values.typeBySpecialty})` : values.typeBySpecialty
  }

  if (values.dwtMin || values.dwtMax) {
    if (s) s += ', '

    if (values.dwtMin && values.dwtMax) {
      s += `DWT ${number(values.dwtMin)} - ${number(values.dwtMax, '', 'mt')}`
    } else {
      s += `${values.dwtMin ? 'min' : 'max'} DWT ${number(values.dwtMin || values.dwtMax, '', 'mt')}`
    }
  }

  return s || 'Vessel details';
}

function minMax(title, min, max, unit) {
  if (!has(min) && !has(max)) return null

  return (
    <div>
      <span className={s.font_medium}>{title}:</span>{' '}
      {number(min)} / {number(max)} {unit}
    </div>
  );
}

function minMaxText(title, min, max, unit) {
  return (
    hasSome(min, max) &&
      `${title} ${
        hasEvery(min, max)
          ? 'min/max'
          : has(min)
            ? 'min'
            : 'max'
      } ${
        hasEvery(min, max)
          ? `${number(min)}/${number(max, '', unit)}`
          : has(min)
            ? number(min, '', unit)
            : number(max, '', unit)
      }`
  )
}

function line(title, value) {
  if (!has(value)) return null

  return (
    <div>
      <span className={s.font_medium}>{title}:</span>{' '}
      {value}
    </div>
  );
}

function multiline(title, arr) {
  const filtered = arr.filter(Boolean)
  if (!filtered?.length) return null

  return (
    <div>
      <span className={s.font_medium}>{title}:</span>{' '}
      {filtered.length === 1 && filtered[0]}
      {filtered.length > 1 && (
        <ul style={{ margin: 0, paddingLeft: '22px' }}>
          {filtered.map((x, i) => <li key={i}>{x}</li>)}
        </ul>
      )}
    </div>
  );
}

function fittedText(title, fitted) {
  return has(fitted) && `${title} ${fitted ? 'fitted' : 'not fitted'}`
}
